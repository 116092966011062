import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

function NewMechanismLinkInput({ row, handleSuggest }) {
  return (
    <div
      id={`suggestDivFull${row}`}
      className="suggestDiv"
      style={{
        display: "none",
        position: "absolute",
        padding: 5,
        backgroundColor: "rgb(23, 55, 151)",
        right: 0,
        left: 0,
        marginInline: "auto",
      }}
    >
      <nobr>
        <input
          id={`suggestInputFull${row}`}
          className="suggestInput"
          placeholder="Publication reference"
        />
        <button
          id={`confirmButtonFull${row}`}
          className="confirmButton"
          onClick={()=>handleSuggest(row)}
        >
          <div
            id={`innerTextSubmittedFull${row}`}
            className="innerTextSubmitted"
            style={{ display: "none" }}
          >
            <FontAwesomeIcon icon={faCheck} color="green" /> Submitted
          </div>{" "}
          <div
            id={`innerTextSubmitFull${row}`}
            className="innerTextSubmit"
            style={{ display: "block" }}
          >
            Submit
          </div>
        </button>
      </nobr>
    </div>
  );
}

export default NewMechanismLinkInput;
