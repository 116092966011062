import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import usePermissionDelete from "../../hooks/usePermissionDelete.js";
import { useState } from "react";
import useAuth from "../../hooks/useAuth.js";
import useAxiosPrivate from "../../hooks/useAxiosPrivate.js";
import { useLocation } from "react-router-dom";

function ReviewRow({ row, tableOnlyPublication, table }) {
  const givePermission = usePermissionDelete();
  const [deletePermissionGiven, setDeletePermissionGiven] = useState(false);
  const { auth } = useAuth();
  const editor = [1984, 5150];
  const axiosPrivate = useAxiosPrivate()
  const location = useLocation()

  async function deleteSuggestion(table,row) {
    try {
       await axiosPrivate.post("/deletesuggestion",{
         suggestionId:table[row].suggestion_id
       })
    } catch(err) {
      console.error(err)
    } finally {
      window.location.reload()
    }
  }

  return (
    <div style={{ position: "relative" }}>
      <button
        style={{
          border: "none",
          backgroundColor: "rgb(245,245,245)",
          cursor: "default",
        }}
        align="center"
        className="infoCircle"
      >
        <FontAwesomeIcon icon={faInfoCircle} color="black" /> Rejected
      </button>
      <div id={`displayComment${row}`} className="displayComment" align="left">
        {
          <>
            Editor's comment: {tableOnlyPublication[row]?.comment ? tableOnlyPublication[row]?.comment : "No comment given"}
            {tableOnlyPublication[row].permission ? (
              editor.includes(auth?.roles) ? (
                <div>
                  <button
                    onClick={()=>deleteSuggestion(tableOnlyPublication,row)}
                    style={{
                      backgroundColor: "rgb(23, 55, 151)",
                      border: "1px solid white",
                      borderRadius: "5px",
                      color:"white"
                    }}
                  >
                    Delete suggestion
                  </button>
                </div>
              ) : (
                <div>
                  <FontAwesomeIcon icon={faCheck} color="white" /> This
                  suggestion will be removed shortly.
                </div>
              )
            ) : deletePermissionGiven ? ( //for immidiate change of state, to not wait for the database update
              <div>
                <FontAwesomeIcon icon={faCheck} color="white" /> This suggestion
                will be removed shortly.
              </div>
            ) : editor.includes(auth?.roles) && location.pathname === "/suggestions" ? (
              <></>
            ) : (
              <div>
                <nobr>
                  Give permission to delete this record?{" "}
                  <button
                    style={{
                      border: "none",
                      backgroundColor: "rgb(23,55,151)",
                      color: "white",
                    }}
                    onClick={() => {
                      givePermission(row, table);
                      setDeletePermissionGiven(true);
                    }}
                  >
                    Yes
                  </button>
                </nobr>
              </div>
            )}
          </>
       }
      </div>
    </div>
  );
}

export default ReviewRow;
