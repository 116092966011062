import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import SmilesChebiRow from "./smilesChebiRow.js";
import InteractiveImage from "./interactiveImage.js";
import CopySmilesButton from "./copySmilesButton.js";
import ExternalLink from "./externalLink.js";
import MoleculeNameButton from "./moleculeNameButton.js";

function CosubstrateRow({
  table,
  row,
  interactiveSearch,
  isQuickSearch,
  suggestion,
  interactiveAdvancedSearch,
  showSubstratePopups
}) {
  const DISABLED_CONDITION = suggestion == undefined ? false : true;
  const cosubstrateImage = suggestion == undefined ? table[row].cosubstrates[0].image : table[row].cosubstrate_image
  const cosubstrateChebi = suggestion == undefined ? table[row].cosubstrates[0].chebi : table[row].cosubstrate_chebi
  const cosubstrateSmiles = suggestion == undefined ? table[row].cosubstrates[0].smiles : table[row].cosubstrate_smiles
  const cosubstrateName = suggestion == undefined ? table[row].cosubstrates[0].name : table[row].cosubstrate_name

  function copySmiles(id) {
    let smiles = document.getElementById(id).innerText;
    let elem = document.createElement("textarea");
    document.body.appendChild(elem);
    smiles = smiles.trim();
    elem.value = smiles;
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
  }

  function handleClick() {
    return isQuickSearch
      ? interactiveSearch(table[row].substrate_name)
      : interactiveAdvancedSearch(showSubstratePopups,"reactName",table[row].substrate_name,false);
  }

  function handleClickCosubstrate() {
    return isQuickSearch
      ? interactiveSearch(cosubstrateName)
      : interactiveAdvancedSearch(showSubstratePopups,"reactName",cosubstrateName,false);
  }

  return (
    <>
      <SmilesChebiRow>
        <li>
          <InteractiveImage
            src={table[row].substrate_image}
            alt={table[row].substrate_smiles}
            className={"responsiveCosubstrate"}
            interactiveSearch={interactiveSearch}
            showPopups={showSubstratePopups}
            interactiveAdvancedSearch={interactiveAdvancedSearch}
            isQuickSearch={isQuickSearch}
            parameter={"reactSmiles"}
            suggestion={suggestion}
          />
          <li>
            <ul style={{ listStyle: "none", padding: 0, margin: "0 auto" }}>
              <CopySmilesButton
                table={table}
                row={row}
                copySmiles={copySmiles}
                substrateOrProduct={"substrate"}
              />

              <li style={{ marginRight: 0, position: "relative" }}>
                {table[row].substrate_chebi ? (
                  <>
                    <ExternalLink
                      externalLink={`https://www.ebi.ac.uk/chebi/searchId.do?chebiId=CHEBI:${table[row].substrate_chebi}`}
                      icon={faExternalLink}
                      idDiv={`goToChebiSubstrate${row}`}
                    >
                      Find in ChEBI: {table[row].substrate_chebi}
                    </ExternalLink>
                  </>
                ) : (
                  <></>
                )}
              </li>
            </ul>
          </li>
          <MoleculeNameButton
            id={"substrateNameQuickSearch"}
            disabled={DISABLED_CONDITION}
            onClick={handleClick}
          >
            {table[row].substrate_name}
          </MoleculeNameButton>
        </li>
        <li>
          <li>
            <InteractiveImage
              src={cosubstrateImage}
              alt={cosubstrateSmiles}
              className={"responsive"}
              interactiveSearch={interactiveSearch}
              showPopups={showSubstratePopups}
              interactiveAdvancedSearch={interactiveAdvancedSearch}
              isQuickSearch={isQuickSearch}
              parameter={"reactSmiles"}
              suggestion={suggestion}
            />
          </li>
          <ul style={{ listStyle: "none", padding: 0, margin: "0 auto" }}>
            <CopySmilesButton
              table={table}
              row={row}
              copySmiles={copySmiles}
              substrateOrProduct={"cosubstrate"}
              cosubstrateSmiles={cosubstrateSmiles}
            />

            <li style={{ marginRight: 0, position: "relative" }}>
              {cosubstrateChebi ? (
                <>
                  <ExternalLink
                    externalLink={`https://www.ebi.ac.uk/chebi/searchId.do?chebiId=CHEBI:${cosubstrateChebi}`}
                    icon={faExternalLink}
                    idDiv={`goToChebiCosubstrate${row}`}
                  >
                    Find in ChEBI: {cosubstrateChebi}
                  </ExternalLink>
                </>
              ) : (
                <></>
              )}
              <br></br>
              <MoleculeNameButton
                id={"cosubstrateNameQuickSearch"}
                disabled={DISABLED_CONDITION}
                onClick={handleClickCosubstrate}
              >
                {cosubstrateName}
              </MoleculeNameButton>
            </li>
          </ul>
        </li>
      </SmilesChebiRow>
      <br />
    </>
  );
}

export default CosubstrateRow;
