function SubmitPartialOrFullForm({
  handleSuggestPartial,
  handleSuggestFull,
  form,
  validChebiCosubstrate,
  validChebiProduct,
  validChebiReactant,
}) {
  return (
    <>
      <ul
        style={{
          listStyle: "none",
          padding: 0,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <li>
          <button
            id="confirmButtonPub"
            onClick={handleSuggestPartial}
            style={{
              display: "none",
              opacity: form.link || form.doi || form.pubmedId ? 1 : 0.5,
            }}
            disabled={form.link || form.doi || form.pubmedId ? false : true}
          >
            Submit
          </button>
        </li>
        <li>
          <button
            id="confirmButtonFull"
            onClick={handleSuggestFull}
            disabled={
              !form.AASequence ||
              !form.species ||
              !form.link ||
              !form.reactSmiles ||
              !form.prodSmiles ||
              !validChebiCosubstrate ||
              !validChebiProduct ||
              !validChebiReactant
                ? true
                : false
            }
            style={{
              display: "none",
              opacity:
                !form.AASequence ||
                !form.species ||
                !form.link ||
                !form.reactSmiles ||
                !form.prodSmiles ||
                !validChebiCosubstrate ||
                !validChebiProduct ||
                !validChebiReactant
                  ? "0.5"
                  : "1",
            }}
          >
            Submit
          </button>
        </li>
      </ul>
    </>
  );
}

export default SubmitPartialOrFullForm;
