import useAxiosPrivate from "./useAxiosPrivate.js"

function usePermissionDelete() {
    const axiosPrivate = useAxiosPrivate()

    async function givePermission(row,table) {
        const suggestionId = table[row].suggestion_id;
        try {
          await axiosPrivate.post("/permission", {
            suggestionId,
          });
        } catch (err) {
          throw err;
        }
      }

      return givePermission

}

export default usePermissionDelete