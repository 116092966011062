import UniprotLink from "../Components/ReactionTable/uniprotLink.js";
import GenbankLink from "../Components/ReactionTable/genbankLink.js";
import SubstrateRow from "../Components/ReactionTable/substrateRow.js";
import ProductRow from "../Components/ReactionTable/productRow.js";
import KingdomIcon from "../Components/ReactionTable/kingdomIcon.js";
import CopySequence from "../Components/ReactionTable/copySequence.js";
import TypeClass from "../Components/ReactionTable/typeClass.js";
import CosubstrateRow from "../Components/ReactionTable/cosubstrateRow.js";
import InteractiveImage from "../Components/ReactionTable/interactiveImage.js";
import SeeSuggestedMechanism from "../Components/Suggestions/seeSuggestedMechanism.js";
import SubmitNewMechanismLink from "../Components/Suggestions/submitNewMechanismLink.js";
import NewMechanismLinkInput from "../Components/Suggestions/newMechanismLinkInput.js";

async function renderMechanismSuggestion({
    table,
    interactiveSearch,
    isQuickSearch,
    interactiveAdvancedSearch,
    showEnzymePopups,
    showProductPopups,
    showSubstratePopups,
    suggestUnclicked,
    seeSuggestionInput,
    submitSuggestionInput
  }) {
    let body = [];

    for (let row = 0; row < table?.length; row++) {
      body.push([
        table[row].name.split("_")[0] === "missing" || !table[row].name ? (
          <>
            <TypeClass
              row={row}
              table={table}
              isQuickSearch={isQuickSearch}
              interactiveSearch={interactiveSearch}
              interactiveAdvancedSearch={interactiveAdvancedSearch}
              showEnzymePopups={showEnzymePopups}
            />
            <UniprotLink
              row={row}
              table={table}
              isQuickSearch={isQuickSearch}
              interactiveSearch={interactiveSearch}
              interactiveAdvancedSearch={interactiveAdvancedSearch}
              showEnzymePopups={showEnzymePopups}
            />
            <GenbankLink
              row={row}
              table={table}
              isQuickSearch={isQuickSearch}
              interactiveSearch={interactiveSearch}
              interactiveAdvancedSearch={interactiveAdvancedSearch}
              showEnzymePopups={showEnzymePopups}
            />
            <CopySequence table={table} row={row} />
            <KingdomIcon
              row={row}
              table={table}
              isQuickSearch={isQuickSearch}
              interactiveSearch={interactiveSearch}
              interactiveAdvancedSearch={interactiveAdvancedSearch}
              showEnzymePopups={showEnzymePopups}
            />
          </>
        ) : (
          <>
            <TypeClass
              row={row}
              table={table}
              isQuickSearch={isQuickSearch}
              interactiveSearch={interactiveSearch}
              interactiveAdvancedSearch={interactiveAdvancedSearch}
              showEnzymePopups={showEnzymePopups}
            />
            <button
              id="enzNameQuickSearch"
              style={{ border: "none", backgroundColor: "rgb(245,245,245)" }}
              onClick={() =>
                isQuickSearch
                  ? interactiveSearch(table[row].name)
                  : interactiveAdvancedSearch(
                      showEnzymePopups,
                      "name",
                      table[row].name,
                      false
                    )
              }
            >
              {table[row].name}
            </button>
            <br />
            <UniprotLink
              row={row}
              table={table}
              isQuickSearch={isQuickSearch}
              interactiveSearch={interactiveSearch}
              interactiveAdvancedSearch={interactiveAdvancedSearch}
              showEnzymePopups={showEnzymePopups}
            />
            <GenbankLink
              row={row}
              table={table}
              isQuickSearch={isQuickSearch}
              interactiveSearch={interactiveSearch}
              interactiveAdvancedSearch={interactiveAdvancedSearch}
              showEnzymePopups={showEnzymePopups}
            />
            <CopySequence table={table} row={row} />
            <KingdomIcon
              row={row}
              table={table}
              isQuickSearch={isQuickSearch}
              interactiveSearch={interactiveSearch}
              interactiveAdvancedSearch={interactiveAdvancedSearch}
              showEnzymePopups={showEnzymePopups}
            />
          </>
        ),
  
        table[row].cosubstrates[0]?.smiles ? (
          <CosubstrateRow
            table={table}
            row={row}
            isQuickSearch={isQuickSearch}
            interactiveSearch={interactiveSearch}
            showSubstratePopups={showSubstratePopups}
            interactiveAdvancedSearch={interactiveAdvancedSearch}
          />
        ) : table[row].substrate_image ? (
          <>
            <InteractiveImage
              src={table[row].substrate_image}
              alt={table[row].substrate_smiles}
              className={"responsive"}
              interactiveSearch={interactiveSearch}
              showPopups={showSubstratePopups}
              interactiveAdvancedSearch={interactiveAdvancedSearch}
              isQuickSearch={isQuickSearch}
              parameter={"reactSmiles"}
            />
            <br />
            <SubstrateRow
              table={table}
              row={row}
              isQuickSearch={isQuickSearch}
              interactiveSearch={interactiveSearch}
              showSubstratePopups={showSubstratePopups}
              interactiveAdvancedSearch={interactiveAdvancedSearch}
            />
          </>
        ) : (
          <>
            {table[row].substrate_smiles}
            <SubstrateRow
              table={table}
              row={row}
              isQuickSearch={isQuickSearch}
              interactiveSearch={interactiveSearch}
              showSubstratePopups={showSubstratePopups}
              interactiveAdvancedSearch={interactiveAdvancedSearch}
            />
            <br />
          </>
        ),
        table[row].product_image ? (
          <>
            <InteractiveImage
              src={table[row].product_image}
              alt={table[row].product_smiles}
              className={"responsive"}
              interactiveSearch={interactiveSearch}
              showPopups={showProductPopups}
              interactiveAdvancedSearch={interactiveAdvancedSearch}
              isQuickSearch={isQuickSearch}
              parameter={"prodSmiles"}
            />
            <br />
            <ProductRow
              table={table}
              row={row}
              isQuickSearch={isQuickSearch}
              interactiveSearch={interactiveSearch}
              showProductPopups={showProductPopups}
              interactiveAdvancedSearch={interactiveAdvancedSearch}
            />
          </>
        ) : (
          <>
            {table[row].product_smiles} <br />
            <ProductRow
              table={table}
              row={row}
              isQuickSearch={isQuickSearch}
              interactiveSearch={interactiveSearch}
              showProductPopups={showProductPopups}
              interactiveAdvancedSearch={interactiveAdvancedSearch}
            />
          </>
        ),

        <>
          {table[row].mechanism_link ? (
            <SeeSuggestedMechanism table={table} row={row}/>
          ) : (
            <div id="mechanismRow">
             <SubmitNewMechanismLink row={row} suggestUnclicked={suggestUnclicked} handleSuggestLink={seeSuggestionInput}/>

              <NewMechanismLinkInput row={row} handleSuggest={submitSuggestionInput} />
            </div>
          )}
        </>,
      ]);
    }
    return body
  }

  export default renderMechanismSuggestion