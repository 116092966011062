function QuickSearchButton({onClick,children,disabled,id}) {
  return (
    <button
      type="button"
      id={id}
      disabled={disabled}
      style={{ position: "relative", marginTop: 10 }}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export default QuickSearchButton;
