import useAxiosPrivate from "./useAxiosPrivate.js";

function useSuggestedAllReactionOrMechanism() {
  const axiosPrivate = useAxiosPrivate();

  async function suggestedAllReactionOrMechanism(seeOnlyPublication) {
    try {
      const response = await axiosPrivate.post("/mysuggestions", {
        seeOnlyPublication,
      });
      return response.data;
    } catch (err) {
      console.error(err);
    }
  }

  return suggestedAllReactionOrMechanism;
}

export default useSuggestedAllReactionOrMechanism;
