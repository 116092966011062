import "./pageCounter.css";
import { useEffect } from "react";
import useDidUpdateEffect from "../../hooks/useDidUpdateEffect.js";
import { useSearchParams } from "react-router-dom";
import Page from "./page.js";

function PageCounter({
  dataCount,
  pageCount,
  setPageCount,
  buttonCount,
  setButtonCount,
  setCount,
}) {
  const [stateParams, setStateParams] = useSearchParams();
  const pageNumber = Math.ceil(dataCount / 10)

  useDidUpdateEffect(() => {
    if (buttonCount >= 5 && pageNumber < 8) {
      goToPage(buttonCount);
    } else if (buttonCount <= 4 && pageCount < pageNumber - 3) {
      goToPage(buttonCount);
    } else if (buttonCount >= 5 && pageCount < pageNumber - 3) {
      goToPage(buttonCount - 1);
    } else if (buttonCount <= 4 && pageCount >= pageNumber - 3) {
      goToPage(buttonCount);
    } else if (buttonCount > 4 && pageCount >= pageNumber - 3) {
      goToPage(buttonCount);
    } else {
      goToPage(1);
    }
  }, [buttonCount,dataCount]);



  useEffect(() => {
    if (!stateParams.get("page")) {
      goToPage(1);
    } else {
      goToPage(stateParams.get("page"));
    }
  },[]);


  function updatePageClicked(number,clickedPage) {
    const nextPage = document?.getElementById(`pages${number}`);
      if (nextPage != undefined) {
        nextPage.style.border = "solid 2px gold";
        nextPage.style.borderRadius = "5px";
      }
      if (clickedPage != undefined) {
        setPageCount(Number(clickedPage.innerText));
        setCount(clickedPage.innerText - 1);
      }
      setButtonCount(number);
  }

  function unclickOtherPages(number) {
    const array = [1, 2, 3, 4, 5, 6, 7];
    array.forEach((element) => {
      if (element != number) {
        const otherPage = document?.getElementById(`pages${element}`);
        if (otherPage != undefined) {
          otherPage.style.border = "solid 2px white";
          otherPage.style.borderRadius = "5px";
        }
      }
    });
  }

  async function goToPage(number) {
  unclickOtherPages(number)

    if (pageNumber < 8) {
      updatePageClicked(number,document?.getElementById(`pages${number}`))
    } else if (pageCount <= 4 && pageCount < pageNumber - 3) {
      const clickedPage = document?.getElementById(`pages${number}`);
      if (number != 5 && number != 6) {
        updatePageClicked(number,clickedPage)
      } else {
        updatePageClicked(4,clickedPage)
      }
    } else if (pageCount <= 4 && pageCount >= pageNumber - 3) {
      updatePageClicked(number,document?.getElementById(`pages${number}`))
    } else if (pageCount >= pageNumber - 3) {
      const clickedPage = document?.getElementById(`pages${number}`);
      if (number != 3 && number != 2) {
        updatePageClicked(number,clickedPage)
      } else {
        updatePageClicked(4,clickedPage)
      }
    } else {
      if (number == 1) {
        updatePageClicked(number,document.getElementById("pages1"))
      } else if (number == 7) {
        updatePageClicked(number,document.getElementById("pages7"))
      } else {
        updatePageClicked(4,document?.getElementById(`pages${number}`))
    }
  }
}

  return (
    <ul
      style={{
        listStyle: "none",
        display: "flex",
        justifyContent: "space-evenly",
        padding: 0,
        listStylePosition: "outside",
      }}
    >
      {" "}
      {pageNumber < 2 ? (
        <li>
        </li>
      ) : (
        <li>
          <button
            id="pages1"
            onClick={() => {
              goToPage(1);
            }}
          >
            1
          </button>
        </li>
      )}
      <li>
        {pageCount > 4 && pageNumber > 7 ? (
          <button id="dotbutton" disabled={true} style={{ color: "black" }}>
            ...
          </button>
        ) : (
          ""
        )}
      </li>
      <Page
        pageNumber={pageNumber}
        pageCount={pageCount}
        goToPage={goToPage}
        goToPageNumber={2}
        buttonNumber={2}
        distanceFromEnd={5}
        middleDistance={pageCount <= 4 ? 0 : 2}
      />
      <Page
        pageNumber={pageNumber}
        pageCount={pageCount}
        goToPage={goToPage}
        goToPageNumber={3}
        buttonNumber={3}
        distanceFromEnd={4}
        middleDistance={pageCount <= 4 ? 0 : 1}
      />
      <Page
        pageNumber={pageNumber}
        pageCount={pageCount}
        goToPage={goToPage}
        goToPageNumber={4}
        buttonNumber={4}
        distanceFromEnd={3}
        middleDistance={0}
      />
      <Page
        pageNumber={pageNumber}
        pageCount={pageCount}
        goToPage={goToPage}
        goToPageNumber={5}
        buttonNumber={5}
        distanceFromEnd={2}
        middleDistance={-1}
      />
      <Page
        pageNumber={pageNumber}
        pageCount={pageCount}
        goToPage={goToPage}
        goToPageNumber={6}
        buttonNumber={6}
        distanceFromEnd={1}
        middleDistance={-2}
      />
      <li>
        {pageCount < pageNumber - 3 &&
        pageNumber > 7 ? (
          <button id="dotbutton" disabled={true} style={{ color: "black" }}>
            ...
          </button>
        ) : (
          ""
        )}
      </li>
      {pageNumber < 7 ? (
        <li></li>
      ) : (
        <>
          <li>
            <button
              onClick={() => {
                goToPage(7);
              }}
              id="pages7"
            >
              {pageNumber}
            </button>
          </li>
        </>
      )}
    </ul>
  );
}

export default PageCounter;
