import { CountUp } from "use-count-up";

function QuickSearchBackground({distinctEnzymeCount,distinctReactionCount,distinctMechanismCount,children}) {
    return (
        <div id="backgroundHome">
        <h6 id="databaseTitle" align="center">
          MARTS-DB is a freely available database of terpene synthases and their
          reaction mechanisms. <br />
          Currently, we store exactly{" "}
          <CountUp isCounting start={0} end={distinctEnzymeCount} /> unique
          enzymes which catalyze{" "}
          <CountUp isCounting start={0} end={distinctReactionCount} />{" "}
          reactions with{" "}
          <CountUp isCounting start={0} end={distinctMechanismCount} /> of
          them having their own mechanism.
        </h6>
        <h1 id="quickSearchTitle" align="center">
          Find your reaction
        </h1>
        <div id="quickSearchInput" align="left">
          {children}
        </div>
      </div>
    )
}

export default QuickSearchBackground