function TextInputOptional({inputId,value,updateForm,name,label}) {
  return (
    <>
      <label for={inputId}>{label}</label>
      <input
        id={inputId}
        name={name}
        onChange={updateForm}
        value={value}
      />
    </>
  );
}

export default TextInputOptional;
