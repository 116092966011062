import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

function SuggestionDivNote({
  row,
  handleSeeSuggestNote,
  handleSubmitSuggestNote,
  placeholder,
  specification,
  table
}) {
    const [noteLength,setNoteLength] = useState(0)
    const note = "Note"
    const isNote = true

  return (
    <>
      <button
        //key={suggestUnclicked}
        id={`suggestNote${specification}${row}`}
        className="suggestNote"
        align="center"
        onClick={() => handleSeeSuggestNote(row,specification)}
        style={{
          border: "none",
          backgroundColor: "rgb(245,245,245)",
        }}
      >
        <i class="fa fa-comment" id="faCommentNote"></i>
      </button>
      <div
        id={`suggestDivNote${specification}${row}`}
        className="suggestDivNote"
        style={{
          display: "none",
          position: "absolute",
          padding: 5,
          backgroundColor: "rgb(23, 55, 151)",
          left: 0,
          right: 0,
          marginInline: "auto",
          width: "fit-content",
        }}
      >
        <nobr>
          <input
            id={`suggestInputNote${specification}${row}`}
            className="suggestInputNote"
            placeholder={placeholder}
            maxLength={200}
            onChange={(event) =>
              setNoteLength(event.target.value.split("").length)
            }
          />
          <button
            id={`confirmButtonNote${specification}${row}`}
            className="confirmButtonNote"
            onClick={() => {
              handleSubmitSuggestNote(row,table,note,specification,isNote);
              setNoteLength(0)
            }}
          >
            <div
              id={`innerTextSubmittedNote${specification}${row}`}
              style={{ display: "none" }}
            >
              <FontAwesomeIcon icon={faCheck} color="green" /> Submitted
            </div>{" "}
            <div
              id={`innerTextSubmitNote${specification}${row}`}
              className="innerTextSubmitNote"
              style={{ display: "block" }}
            >
              Submit
            </div>
          </button>
        </nobr>
        <p
          style={{ color: "white", fontSize: 10, margin: "0 auto" }}
          align="left"
        >
          {noteLength} / 200
        </p>
      </div>
    </>
  );
}

export default SuggestionDivNote;
