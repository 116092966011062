import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import useAuth from "./useAuth.js";


function useComment() {
    const axiosPrivate = useAxiosPrivate()
    const {auth} = useAuth()

    async function submitSuggestComm(row, col, table) {
        let radioValue;
        const idArray = [
          `enzymeGenbankComment${row}`,
          `enzymeUniprotComment${row}`,
          `enzymeKingdomComment${row}`,
          `enzymeSpeciesComment${row}`,
          `enzymeClassComment${row}`,
          `enzymeTypeComment${row}`,
          `enzymeNameComment${row}`,
          `enzymeAASequenceComment${row}`,
          `substrateNameComment${row}`,
          `substrateChebiComment${row}`,
          `substrateImageComment${row}`,
          `substrateSmilesComment${row}`,
          `productNameComment${row}`,
          `productChebiComment${row}`,
          `productImageComment${row}`,
          `productSmilesComment${row}`,
        ];
    
        for (let i = 0; i < idArray.length; i++) {
          if (document.getElementById(idArray[i]).checked) {
            radioValue = document.getElementById(idArray[i]).value;
          }
        }
    
        const inputValueComment = document.getElementById(
          `suggestInputComm${row}${col}`
        ).value;
        document.getElementById(
          `innerTextSubmittedComm${row}${col}`
        ).style.display = "block";
        document.getElementById(`innerTextSubmitComm${row}${col}`).style.display =
          "none";
    
        try {
          await axiosPrivate.post("/postcomment", {
            inputValueComment: inputValueComment,
            enzyme_id: table[row].enzyme_id,
            reaction_id: table[row].reaction_id,
            substrate_id: table[row].substrate,
            product_id: table[row].product,
            RE_pair: table[row].reaction_enzyme_pair_id,
            mechanism_id: table[row].mechanism_id,
            subject: radioValue,
            username: auth?.username,
          });
        } catch (err) {
          throw err;
        } finally {
          setTimeout(() => {
            document.getElementById(`suggestDivComm${row}${col}`).style.display =
              "none";
            document.getElementById(
              `innerTextSubmittedComm${row}${col}`
            ).style.display = "none";
            document.getElementById(
              `innerTextSubmitComm${row}${col}`
            ).style.display = "block";
          }, 1000);
        }
      }

      return submitSuggestComm
}

export default useComment

