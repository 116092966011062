import Footer from "../Components/Footer.js";
import HomeNavbar from "../HomeNavbar.js";
import { useEffect } from "react";
import SuggestionTable from "../suggestionTable.js";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useSuggestedAllReactionOrMechanism from "../hooks/useSuggestedAllReactionOrMechanism.js";
import renderAllPublicationSuggestion from "../api/renderAllPublicationSuggestion.js";
import renderAllFullSuggestion from "../api/renderAllFullSuggestion.js";
import SmallLoading from "../Components/smallLoading.js";
import NoSuggestionMessage from "../Components/Suggestions/noSuggestionMessage.js";
import { useLocation } from "react-router-dom";


function AllSuggestions() {
  const [reactionBody, setReactionBody] = useState([]);
  const [table, setTable] = useState([]);
  const [isLoading,setIsLoading] = useState(true)
  const [columnBody, setColumnBody] = useState({
    first: [],
    second: [],
    third: [],
    fourth: [],
  });
  const navigate = useNavigate();
  const location = useLocation()

  const suggestedAllReactionOrMechanism = useSuggestedAllReactionOrMechanism()
  const suggestion = true


  function review(table,row) {
    if (
      (table[row].aminoacid_sequence ||
        table[row].substrate_smiles ||
        table[row].product_smiles) &&
      !table[row].re_pair
    ) {
      navigate("/reactionentry", {
        state: {
          enzyme_name: table[row].enzyme_name,
          aminoacid_sequence: table[row].aminoacid_sequence,
          kingdom: table[row].kingdom,
          species: table[row].species,
          class: table[row].class,
          type: table[row].type,
          uniprot_id: table[row].uniprot_id,
          genbank_id: table[row].genbank_id,
          substrate_smiles: table[row].substrate_smiles,
          substrate_chebi: table[row].substrate_chebi,
          substrate_name: table[row].substrate_name,
          cosubstrate_smiles: table[row].cosubstrate_smiles,
          cosubstrate_chebi: table[row].cosubstrate_chebi,
          cosubstrate_name: table[row].cosubstrate_name,
          product_smiles: table[row].product_smiles,
          product_name: table[row].product_name,
          product_chebi: table[row].product_chebi,
          suggestion_id: table[row].suggestion_id,
          publication_link: table[row].publication_link,
          publication_pmid: table[row].publication_pmid,
          publication_doi: table[row].publication_doi,
          from:location.pathname
        },
      });
    } else if (table[row].re_pair) {
      navigate("/newmechanism", {
        state: {
          product_name: table[row].product_name,
          product_chebi: [table[row].product_chebi],
          product_smiles: [table[row].product_smiles],
          substrate_smiles: [table[row].substrate_smiles],
          RE_pair: [table[row].re_pair],
          suggestion_id: table[row].suggestion_id,
          row: 0,
          from:location.pathname
        },
      });
    } else {
      navigate("/reactionentry",{
        state: {
          from:location.pathname,
          suggestion_id:table[row].suggestion_id
        }
      });
    }
  }

  async function onlyPublicationSuggestion() {
    const tableOnlyPublication = await suggestedAllReactionOrMechanism(true);

    const onlyPublicationSuggestionBody = await renderAllPublicationSuggestion(
      tableOnlyPublication,
      review,
      table
    );
    setIsLoading(false);
    let firstColumn = [];
    let secondColumn = [];
    let thirdColumn = [];
    let fourthColumn = [];
    for (let i = 0; i < onlyPublicationSuggestionBody.length; i++) {
      firstColumn.push(
        onlyPublicationSuggestionBody[0 + i * 4]
          ? onlyPublicationSuggestionBody[0 + i * 4]
          : []
      );
      secondColumn.push(
        onlyPublicationSuggestionBody[1 + i * 4]
          ? onlyPublicationSuggestionBody[1 + i * 4]
          : []
      );
      thirdColumn.push(
        onlyPublicationSuggestionBody[2 + i * 4]
          ? onlyPublicationSuggestionBody[2 + i * 4]
          : []
      );
      fourthColumn.push(
        onlyPublicationSuggestionBody[3 + i * 4]
          ? onlyPublicationSuggestionBody[3 + i * 4]
          : []
      );
    }
    setColumnBody(() => ({
      first: firstColumn,
      second: secondColumn,
      third: thirdColumn,
      fourth: fourthColumn,
    }));
  }

  async function displaySuggestions() {
    const table = await suggestedAllReactionOrMechanism(false);
    setTable(table);
    setIsLoading(false);
    const reactionBody = await renderAllFullSuggestion(
      table,
      suggestion,
      review
    );
    setReactionBody(reactionBody);
  }

  useEffect(() => {
    displaySuggestions();
    onlyPublicationSuggestion()
  }, [table.length]);


  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css"
          rel="stylesheet"
        />
      </head>
      <body id="body">
        <HomeNavbar style={{ zIndex: 3 }} id="HomeNavbar" />

        <div id="mySuggestions">
          {isLoading ? (
            <>
              <SmallLoading />
            </>
          ) : reactionBody.length > 0 && columnBody.first.length > 0 ? (
            <>
              <h2>Full suggestions of reactions and their mechanisms:</h2>
              <SuggestionTable body={reactionBody} display={"none"} />
              <div style={{ marginTop: 50 }}>
                <h2>Suggested publication references:</h2>
              </div>
              <div id="publicationFourColumns">
                <SuggestionTable body={columnBody.first} display={"none"} />
                <SuggestionTable body={columnBody.second} display={"none"} />
                <SuggestionTable body={columnBody.third} display={"none"} />
                <SuggestionTable body={columnBody.fourth} display={"none"} />
              </div>
            </>
          ) : reactionBody.length > 0 && columnBody.first.length === 0 ? (
            <>
              <h2>Full suggestions of reactions and their mechanisms:</h2>
              <SuggestionTable body={reactionBody} display={"none"} />
            </>
          ) : reactionBody.length === 0 && columnBody.first.length > 0 ? (
            <>
              <div style={{ marginTop: 50 }}>
                <h2>Suggested publication references:</h2>
              </div>
              <div id="publicationFourColumns">
                <SuggestionTable body={columnBody.first} display={"none"} />
                <SuggestionTable body={columnBody.second} display={"none"} />
                <SuggestionTable body={columnBody.third} display={"none"} />
                <SuggestionTable body={columnBody.fourth} display={"none"} />
              </div>
            </>
          ) : (
            <NoSuggestionMessage />
          )}
        </div>
        <Footer />
      </body>
    </html>
  );
}

export default AllSuggestions;
