import { Spin } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import { LoadingOutlined } from "@ant-design/icons";
import QuickSearchButton from "./quickSearchButton.js";

function NextPreviousButtons({
  isLoading,
  firstSearch,
  dataCount,
  handlePrevious,
  handleNext,
  count,
  seeAllReactions,
}) {
  return (
    <ul id="nextResultsPrevious">
      <li>
        <nobr>
          <QuickSearchButton
            onClick={handlePrevious}
            disabled={count == 0 ? true : false}
            id="scrollprevious"
          >
            <FontAwesomeIcon icon={faAngleDoubleLeft} /> Previous
          </QuickSearchButton>
        </nobr>
      </li>
      <li>
        {isLoading && firstSearch ? (
          <>
            <Spin
              indicator={
                <LoadingOutlined style={{ color: "rgb(23, 55, 151)" }} spin />
              }
            />{" "}
            Searching for results, please wait...
          </>
        ) : dataCount === 0 ? (
          <></>
        ) : (
          <>
            <FontAwesomeIcon icon={faCheck} color="green" /> {dataCount} results
            found
          </>
        )}
      </li>
      <li>
        <nobr>
          <QuickSearchButton
            onClick={handleNext}
            id="scrollnext"
            disabled={
              count >= dataCount - 1 || (seeAllReactions && dataCount < 12)
                ? true
                : false
            }
          >
            <FontAwesomeIcon icon={faAngleDoubleRight} /> Next
          </QuickSearchButton>
        </nobr>
      </li>
    </ul>
  );
}

export default NextPreviousButtons;
