import axios from "../api/axios.js"
import useAuth from "./useAuth.js"
import { useNavigate } from "react-router-dom";


function useLogout() {
    const {setAuth} = useAuth()
    const navigate = useNavigate()
    
    async function logout() {
        setAuth({})
        try {
            await axios.post("/logout",{
                withCredentials:true
            })
            navigate("/login",{});
        } catch (err) {
            console.error(err)
        }
    }

    return logout
}

export default useLogout