function QuickSearchExamples({setTriggerUseEffect,setQuickSearchValue,setInteractiveSearchSwitch}) {
    return (
        <p id="quickSearchExamples">
              Examples:{" "}
              <button
                style={{
                  border: "none",
                  backgroundColor: "rgb(0,0,0,0.0)",
                  color: "white",
                  fontWeight: "bold",
                  padding: 0,
                }}
                onClick={(event) => {
                  event.preventDefault();
                  setQuickSearchValue("FPP")
                  setTriggerUseEffect(current => !current)
                  setInteractiveSearchSwitch((current) => !current)
                }}
              >
                FPP
              </button>
              ,{" "}
              <button
                style={{
                  border: "none",
                  backgroundColor: "rgb(0,0,0,0.0)",
                  color: "white",
                  fontWeight: "bold",
                  padding: 0,
                }}
                onClick={(event) => {
                  event.preventDefault();
                  setQuickSearchValue("Diterpene synthase")
                  setTriggerUseEffect(current => !current)
                  setInteractiveSearchSwitch((current) => !current)
                }}
              >
                Diterpene synthase
              </button>
              ,{" "}
              <button
                style={{
                  border: "none",
                  backgroundColor: "rgb(0,0,0,0.0)",
                  color: "white",
                  fontWeight: "bold",
                  padding: 0,
                }}
                onClick={(event) => {
                  event.preventDefault();
                  setQuickSearchValue("coral")
                  setTriggerUseEffect(current => !current)
                  setInteractiveSearchSwitch((current) => !current)
                }}
              >
                coral
              </button>
              ,{" "}
              <button
                style={{
                  border: "none",
                  backgroundColor: "rgb(0,0,0,0.0)",
                  color: "white",
                  fontWeight: "bold",
                  padding: 0,
                }}
                onClick={(event) => {
                  event.preventDefault();
                  setQuickSearchValue("W6HUT3")
                  setTriggerUseEffect(current => !current)
                  setInteractiveSearchSwitch((current) => !current)
                }}
              >
                W6HUT3
              </button>
              ,{" "}
              <button
                style={{
                  border: "none",
                  backgroundColor: "rgb(0,0,0,0.0)",
                  color: "white",
                  fontWeight: "bold",
                  padding: 0,
                }}
                onClick={(event) => {
                  event.preventDefault();
                  setQuickSearchValue("germacrene")
                  setTriggerUseEffect(current => !current)
                  setInteractiveSearchSwitch((current) => !current)
                }}
              >
                germacrene
              </button>
              ,{" "}
              <button
                style={{
                  border: "none",
                  backgroundColor: "rgb(0,0,0,0.0)",
                  color: "white",
                  fontWeight: "bold",
                  padding: 0,
                }}
                onClick={(event) => {
                  event.preventDefault();
                  setQuickSearchValue("CC1=CCC2(C(C)C)CC12")
                  setTriggerUseEffect(current => !current)
                  setInteractiveSearchSwitch((current) => !current)
                }}
              >
                CC1=CCC2(C(C)C)CC12
              </button>
            </p>
    )
}

export default QuickSearchExamples