import { faBook } from "@fortawesome/free-solid-svg-icons";
import ExternalLink from "../Components/ReactionTable/externalLink.js";
import DisplayOnHover from "../Components/Suggestions/displayOnHover.js";
import ReviewRow from "../Components/Suggestions/reviewRow.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

function renderAllPublicationSuggestion(
  tableOnlyPublication,
  review,
  table
) {
  let onlyPublicationSuggestionBody = [];

  for (let row = 0; row < tableOnlyPublication.length; row++) {
    onlyPublicationSuggestionBody.push([
      <>
        <div id="reactionSuggestionRow">
          Reaction:{" "}
          <ExternalLink
            idLink={`reactionPublicationOnly${row}`}
            idDiv={`goToReactionPublicationOnly${row}`}
            externalLink={tableOnlyPublication[row].publication_link}
            icon={faBook}
          >
            See suggested publication
          </ExternalLink>
        </div>

        <div id="reactionSuggestionRow">
          {tableOnlyPublication[row].mechanism_link ? (
            <>
              Mechanism:
              <nobr style={{ padding: 0 }}>
                <ExternalLink
                  idLink={`mechanismPublicationOnly${row}`}
                  idDiv={`goToMechanismPublicationOnly${row}`}
                  externalLink={tableOnlyPublication[row].mechanism_link}
                  icon={faBook}
                >
                  See suggested publication
                </ExternalLink>
              </nobr>
            </>
          ) : (
            <></>
          )}
        </div>
        {tableOnlyPublication[row].note ? (
          <div style={{ position: "relative" }}>
            Note:{" "}
            <DisplayOnHover
              row={row}
              tableOnlyPublication={tableOnlyPublication}
              idDiv={`displayNoteOnly${row}`}
            />
          </div>
        ) : (<></>)}
        {tableOnlyPublication[row].review === 0 ? (
          <button onClick={()=>review(tableOnlyPublication,row)} style={{border:"none",backgroundColor:"rgb(245,245,245)"}}>
            Review now!
          </button>  
        ) : tableOnlyPublication[row].review === 1 ? (
          <>
            <FontAwesomeIcon icon={faCheck} color="green" /> Reviewed!
          </>
        ) : (
            <ReviewRow row={row} tableOnlyPublication={tableOnlyPublication} table={table} />
        )}
      </>,
    ]);
  }
  return onlyPublicationSuggestionBody
}

export default renderAllPublicationSuggestion;
