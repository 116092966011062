import { faBook } from "@fortawesome/free-solid-svg-icons";
import ExternalLink from "../Components/ReactionTable/externalLink.js";
import SuggestionDiv from "../Components/Suggestions/suggestionDiv.js";
import DisplayOnHover from "../Components/Suggestions/displayOnHover.js";
import SuggestionDivNote from "../Components/Suggestions/suggestionDivNote.js";
import ReviewRow from "../Components/Suggestions/reviewRow.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck,faTimes } from "@fortawesome/free-solid-svg-icons";

function renderPublicationSuggestion(
  tableOnlyPublication,
  seeSuggestionInput,
  submitSuggestAdditional,
  seeSuggestNote,
  table
) {
  let onlyPublicationSuggestionBody = [];

  for (let row = 0; row < tableOnlyPublication.length; row++) {
    onlyPublicationSuggestionBody.push([
      <>
        <div id="reactionSuggestionRow">
          Reaction:{" "}
          <ExternalLink
            idLink={`reactionPublicationOnly${row}`}
            idDiv={`goToReactionPublicationOnly${row}`}
            externalLink={tableOnlyPublication[row].publication_link}
            icon={faBook}
          >
            See suggested publication
          </ExternalLink>
        </div>

        <div id="reactionSuggestionRow">
          {tableOnlyPublication[row].mechanism_link ? (
            <>
              Mechanism:
              <nobr style={{ padding: 0 }}>
                <ExternalLink
                  idLink={`mechanismPublicationOnly${row}`}
                  idDiv={`goToMechanismPublicationOnly${row}`}
                  externalLink={tableOnlyPublication[row].mechanism_link}
                  icon={faBook}
                >
                  See suggested publication
                </ExternalLink>
              </nobr>
            </>
          ) : (
            <></>
          )}
        </div>
        <div style={{ position: "relative" }}>
          {tableOnlyPublication[row].mechanism_link ? (
            <></>
          ) : (
            <div id="mechanismRow">
              <nobr>
                Mechanism:{" "}
                <button
                  //key={suggestUnclicked}
                  id={`suggestMechanism${row}`}
                  className="suggestMechanism"
                  align="center"
                  onClick={() => seeSuggestionInput(row,"OnlyPub")}
                  style={{
                    border: "none",
                    backgroundColor: "rgb(245,245,245)",
                  }}
                >
                  <i class="fa fa-comment" id="faCommentMechanism"></i>
                </button>
              </nobr>
              <SuggestionDiv
                row={row}
                handleSuggest={submitSuggestAdditional}
                placeholder={"Publication reference"}
                specification={"OnlyPub"}
                table={tableOnlyPublication}
              />
            </div>
          )}
        </div>
        {tableOnlyPublication[row].note ? (
          <div style={{ position: "relative" }}>
            Note:{" "}
            <DisplayOnHover
              row={row}
              tableOnlyPublication={tableOnlyPublication}
              idDiv={`displayNoteOnly${row}`}
            />
          </div>
        ) : (
          <div style={{ position: "relative" }}>
            Note:
            <SuggestionDivNote
              row={row}
              handleSeeSuggestNote={seeSuggestNote}
              handleSubmitSuggestNote={submitSuggestAdditional}
              placeholder={"Add a note"}
              specification={"OnlyPub"}
              table={tableOnlyPublication}
            />
          </div>
        )}
        {tableOnlyPublication[row].review === 0 ? (
          <>
            <FontAwesomeIcon icon={faTimes} color="red" /> Not reviewed yet
          </>
        ) : tableOnlyPublication[row].review === 1 ? (
          <>
            <FontAwesomeIcon icon={faCheck} color="green" /> Reviewed!
          </>
        ) : (
            <ReviewRow row={row} tableOnlyPublication={tableOnlyPublication} table={table} />
        )}
      </>,
    ]);
  }
  return onlyPublicationSuggestionBody
}

export default renderPublicationSuggestion;
