import CheckboxInput from "./chechboxInput.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClone,
  faFlask,
  faBrain,
  faCalculator,
} from "@fortawesome/free-solid-svg-icons";

function EvidenceFilters({ updateForm,searchParams }) {
  return (
    <>
      <CheckboxInput
        idLabel={"similarityevidence"}
        idInput={"SimilarityEvidence"}
        inputText={
          <>
            <FontAwesomeIcon icon={faClone} color="black" /> Similarity
          </>
        }
        value={"similarity"}
        name={"similarity"}
        updateForm={updateForm}
        searchParams={searchParams}
      />
      <CheckboxInput
        idLabel={"experimentevidence"}
        idInput={"ExperimentEvidence"}
        inputText={
          <>
            <FontAwesomeIcon icon={faFlask} /> Experiment
          </>
        }
        value={"experiment"}
        name={"experiment"}
        updateForm={updateForm}
        searchParams={searchParams}
      />
      <CheckboxInput
        idLabel={"trivialevidence"}
        idInput={"TrivialEvidence"}
        inputText={
          <>
            <FontAwesomeIcon icon={faBrain} /> Trivial
          </>
        }
        value={"trivial"}
        name={"trivial"}
        updateForm={updateForm}
        searchParams={searchParams}
      />
      <CheckboxInput
        idLabel={"calculationevidence"}
        idInput={"CalculationEvidence"}
        inputText={
          <>
            <FontAwesomeIcon icon={faCalculator} /> Calculated
          </>
        }
        value={"calculation"}
        name={"calculation"}
        updateForm={updateForm}
        searchParams={searchParams}
      />
    </>
  );
}

export default EvidenceFilters;
