import findMechanism from "../api/findMechanism.js";

async function renderMechanism({
  table,
  row,
  setMechBody,
  isQuickSearch,
  interactiveAdvancedSearch,
  showPopups,
  mechanismUnclicked,
}) {
  let foundMechanism = [];
  foundMechanism = await findMechanism({
    table,
    row,
    isQuickSearch,
    interactiveAdvancedSearch,
    showPopups,
    mechanismUnclicked,
  });
  setMechBody(foundMechanism.mechanismBody);
  document.getElementById("overlap").style.display = "block";
  document.getElementById("mechDiv").style.display = "block";
  const elements = document.querySelectorAll("#body *");
  elements.forEach((element) => {
    element.style.filter = "blur(3px)";
  });
}

export default renderMechanism;
