import React, { Component } from "react";
import "./reactionTable.css";
import { faCopy, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const user = [5150, 1984];

async function showComment(row, col, rowCount) {
  if (
    document.getElementById(`suggestDivComm${row}${col}`).style.display ===
    "none"
  ) {
    document.getElementById(`suggestDivComm${row}${col}`).style.display =
      "block";
    document.getElementById(`suggestInputComm${row}${col}`).value = "";
    for (let r = 0; r < rowCount; r++) {
      document.getElementById(`enzymeGenbankComment${r}`).checked = false;
      document.getElementById(`enzymeUniprotComment${r}`).checked = false;
      document.getElementById(`enzymeKingdomComment${r}`).checked = false;
      document.getElementById(`enzymeClassComment${r}`).checked = false;
      document.getElementById(`enzymeTypeComment${r}`).checked = false;
      document.getElementById(`enzymeSpeciesComment${r}`).checked = false;
      document.getElementById(`enzymeAASequenceComment${r}`).checked = false;
      document.getElementById(`enzymeNameComment${r}`).checked = false;
      document.getElementById(`substrateNameComment${r}`).checked = false;
      document.getElementById(`substrateChebiComment${r}`).checked = false;
      document.getElementById(`substrateSmilesComment${r}`).checked = false;
      document.getElementById(`substrateImageComment${r}`).checked = false;
      document.getElementById(`productSmilesComment${r}`).checked = false;
      document.getElementById(`productImageComment${r}`).checked = false;
      document.getElementById(`productChebiComment${r}`).checked = false;
      document.getElementById(`productNameComment${r}`).checked = false;
      document.getElementById(`nobrEnzyme${r}`).style.display = "none";
      document.getElementById(`nobrSubstrate${r}`).style.display = "none";
      document.getElementById(`nobrProduct${r}`).style.display = "none";

      for (let c = 0; c < 3; c++) {
        if (r != row || c != col) {
          if (document.getElementById(`suggestDivComm${r}${c}`)) {
            document.getElementById(`suggestDivComm${r}${c}`).style.display =
              "none";
            document.getElementById(`suggestInputComm${r}${c}`).value = "";
          }
        }
      }
    }
  } else {
    document.getElementById(`suggestDivComm${row}${col}`).style.display =
      "none";
    document.getElementById(`suggestInputComm${row}${col}`).value = "";
    for (let r = 0; r < rowCount; r++) {
      for (let c = 0; c < 3; c++) {
        if (r != row || c != col) {
          if (document.getElementById(`suggestDivComm${r}${c}`)) {
            document.getElementById(`suggestDivComm${r}${c}`).style.display =
              "none";
            document.getElementById(`suggestInputComm${r}${c}`).value = "";
          }
        }
      }
    }
  }
}

class Table extends Component {
  render() {
    let body = this.props.body;
    let display = this.props.display;
    let rowCount = body.length;
    let commentFunction = this.props.commentFunction;
    let auth = this.props.auth;
    let seeAllReactions = this.props.seeAllReactions;

    return (
      <table id="table" align="center">
        {body.map((rowContent, rowID) => (
          <tbody style={{ breakInside: "avoid-column" }}>
            {rowID == 0 ? (
              <tr id="headerRow">
                {["Enzyme", "Substrate", "Product", "Details"].map(
                  (head, headID) => (
                    <th
                      id={`headerCol${headID}`}
                      style={{ position: "relative" }}
                    >
                      {head}
                      {/* {headID === 3 ? (
                        <button
                          id="closeView"
                          style={{
                            border: "none",
                            display: display,
                            backgroundColor: "rgb(235, 235, 235)",
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                          }}
                          onClick={() => {
                            document.getElementById("overlap").style.display =
                              "none";
                            document.getElementById(
                              "reactionDiv"
                            ).style.display = "none";
                            const elements = document.querySelectorAll("#body");
                            elements.forEach((element) => {
                              element.style.filter = "blur(0px)";
                            });
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faTimes}
                            color="rgb(150,150,150)"
                            style={{
                              fontWeight: "lighter",
                              margin: 0,
                              padding: 0,
                            }}
                          />
                        </button>
                      ) : (
                        <></>
                      )} */}
                    </th>
                  )
                )}
              </tr>
            ) : (
              <></>
            )}
            <TableRow
              rowContent={rowContent}
              id={`TR${rowID}`}
              rowCount={rowCount}
              commentFunction={commentFunction}
              auth={auth}
              seeAllReactions={seeAllReactions}
            />
          </tbody>
        ))}
      </table>
    );
  }
}

class TableRow extends Component {
  render() {
    let row = this.props.rowContent;
    let TRid = this.props.id;
    let rowCount = this.props.rowCount;
    let commentFunction = this.props.commentFunction;
    let auth = this.props.auth;
    let seeAllReactions = this.props.seeAllReactions;

    return (
      <tr
        id={`${TRid}`}
        className="reactionContent"
        style={{
          boxShadow:
            "0 6px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2)",
        }}
      >
        {row.map((val, colID) => (
          <td
            key={colID}
            id={seeAllReactions ? "dataColTwoColumns" : "dataCol"}
            style={{ position: "relative" }}
          >
            {user.includes(auth?.roles) ? (
              <>
                {colID == 0 ? (
                  <>
                    <button
                      id="commentButtonEnzyme"
                      onClick={() => {
                        showComment(TRid.split("")[2], colID, rowCount);
                      }}
                    >
                      Comment
                    </button>
                    <div
                      id={`suggestDivComm${TRid.split("")[2]}${colID}`}
                      className="suggestDivComm"
                      align="left"
                    >
                      {" "}
                      <p
                        style={{
                          color: "white",
                          borderBottom: "1px solid white",
                          marginBottom: 5,
                        }}
                      >
                        Choose an attribute to comment on:
                      </p>
                      <input
                        id={`enzymeNameComment${TRid.split("")[2]}`}
                        type="radio"
                        value="Name"
                        name={`enzyme${TRid.split("")[2]}`}
                        onClick={() =>
                          (document.getElementById(
                            `nobrEnzyme${TRid.split("")[2]}`
                          ).style.display = "block")
                        }
                      />
                      <label
                        for={`enzymeNameComment${TRid.split("")[2]}`}
                        style={{ color: "white", marginLeft: 5 }}
                      >
                        Name
                      </label>
                      <br />
                      <input
                        id={`enzymeAASequenceComment${TRid.split("")[2]}`}
                        type="radio"
                        value="AASequence"
                        name={`enzyme${TRid.split("")[2]}`}
                        onClick={() =>
                          (document.getElementById(
                            `nobrEnzyme${TRid.split("")[2]}`
                          ).style.display = "block")
                        }
                      />
                      <label
                        for={`enzymeAASequenceComment${TRid.split("")[2]}`}
                        style={{ color: "white", marginLeft: 5 }}
                      >
                        Aminoacid sequence
                      </label>
                      <br />
                      <input
                        id={`enzymeKingdomComment${TRid.split("")[2]}`}
                        type="radio"
                        value="Kingdom"
                        name={`enzyme${TRid.split("")[2]}`}
                        onClick={() =>
                          (document.getElementById(
                            `nobrEnzyme${TRid.split("")[2]}`
                          ).style.display = "block")
                        }
                      />
                      <label
                        for={`enzymeKingdomComment${TRid.split("")[2]}`}
                        style={{ color: "white", marginLeft: 5 }}
                      >
                        Kingdom
                      </label>
                      <br />
                      <input
                        id={`enzymeSpeciesComment${TRid.split("")[2]}`}
                        type="radio"
                        value="Species"
                        name={`enzyme${TRid.split("")[2]}`}
                        onClick={() =>
                          (document.getElementById(
                            `nobrEnzyme${TRid.split("")[2]}`
                          ).style.display = "block")
                        }
                      />
                      <label
                        for={`enzymeSpeciesComment${TRid.split("")[2]}`}
                        style={{ color: "white", marginLeft: 5 }}
                      >
                        Species
                      </label>
                      <br />
                      <input
                        id={`enzymeClassComment${TRid.split("")[2]}`}
                        type="radio"
                        value="Class"
                        name={`enzyme${TRid.split("")[2]}`}
                        onClick={() =>
                          (document.getElementById(
                            `nobrEnzyme${TRid.split("")[2]}`
                          ).style.display = "block")
                        }
                      />
                      <label
                        for={`enzymeClassComment${TRid.split("")[2]}`}
                        style={{ color: "white", marginLeft: 5 }}
                      >
                        Class
                      </label>
                      <br />
                      <input
                        id={`enzymeTypeComment${TRid.split("")[2]}`}
                        type="radio"
                        value="Type"
                        name={`enzyme${TRid.split("")[2]}`}
                        onClick={() =>
                          (document.getElementById(
                            `nobrEnzyme${TRid.split("")[2]}`
                          ).style.display = "block")
                        }
                      />
                      <label
                        for={`enzymeTypeComment${TRid.split("")[2]}`}
                        style={{ color: "white", marginLeft: 5 }}
                      >
                        Type
                      </label>
                      <br />
                      <input
                        id={`enzymeUniprotComment${TRid.split("")[2]}`}
                        type="radio"
                        value="UniprotID"
                        name={`enzyme${TRid.split("")[2]}`}
                        onClick={() =>
                          (document.getElementById(
                            `nobrEnzyme${TRid.split("")[2]}`
                          ).style.display = "block")
                        }
                      />
                      <label
                        for={`enzymeUniprotComment${TRid.split("")[2]}`}
                        style={{ color: "white", marginLeft: 5 }}
                      >
                        Uniprot ID
                      </label>
                      <br />
                      <input
                        id={`enzymeGenbankComment${TRid.split("")[2]}`}
                        type="radio"
                        value="GenbankID"
                        name={`enzyme${TRid.split("")[2]}`}
                        onClick={() =>
                          (document.getElementById(
                            `nobrEnzyme${TRid.split("")[2]}`
                          ).style.display = "block")
                        }
                      />
                      <label
                        for={`enzymeGenbankComment${TRid.split("")[2]}`}
                        style={{ color: "white", marginLeft: 5 }}
                      >
                        Genbank ID
                      </label>
                      <br />
                      <nobr
                        id={`nobrEnzyme${TRid.split("")[2]}`}
                        style={{
                          display: "none",
                          borderTop: "1px white solid",
                        }}
                      >
                        <input
                          id={`suggestInputComm${TRid.split("")[2]}${colID}`}
                          className="suggestInputComm"
                          placeholder="What to improve?"
                          maxLength={200}
                          style={{ marginTop: 5 }}
                        />
                        <button
                          id={`confirmButtonComm${TRid.split("")[2]}${colID}`}
                          className="confirmButtonComm"
                          style={{ marginTop: 5 }}
                          onClick={() => {
                            commentFunction(TRid.split("")[2], colID);
                          }}
                        >
                          <div
                            id={`innerTextSubmittedComm${
                              TRid.split("")[2]
                            }${colID}`}
                            style={{ display: "none" }}
                          >
                            <FontAwesomeIcon icon={faCheck} color="green" />{" "}
                            Submitted
                          </div>{" "}
                          <div
                            id={`innerTextSubmitComm${
                              TRid.split("")[2]
                            }${colID}`}
                            style={{ display: "block" }}
                          >
                            Submit
                          </div>
                        </button>
                      </nobr>
                    </div>
                  </>
                ) : colID == 1 ? (
                  <>
                    <button
                      id="commentButtonSubstrate"
                      onClick={() => {
                        showComment(TRid.split("")[2], colID, rowCount);
                      }}
                    >
                      Comment
                    </button>
                    <div
                      id={`suggestDivComm${TRid.split("")[2]}${colID}`}
                      className="suggestDivComm"
                      align="left"
                    >
                      <p
                        style={{
                          color: "white",
                          borderBottom: "1px solid white",
                          marginBottom: 5,
                        }}
                      >
                        Choose an attribute to comment on:
                      </p>
                      <input
                        id={`substrateNameComment${TRid.split("")[2]}`}
                        type="radio"
                        value="substrateName"
                        name={`substrate${TRid.split("")[2]}`}
                        onClick={() =>
                          (document.getElementById(
                            `nobrSubstrate${TRid.split("")[2]}`
                          ).style.display = "block")
                        }
                      />
                      <label
                        for={`substrateNameComment${TRid.split("")[2]}`}
                        style={{ color: "white", marginLeft: 5 }}
                      >
                        Name
                      </label>
                      <br />
                      <input
                        id={`substrateSmilesComment${TRid.split("")[2]}`}
                        type="radio"
                        value="substrateSmiles"
                        name={`substrate${TRid.split("")[2]}`}
                        onClick={() =>
                          (document.getElementById(
                            `nobrSubstrate${TRid.split("")[2]}`
                          ).style.display = "block")
                        }
                      />
                      <label
                        for={`substrateSmilesComment${TRid.split("")[2]}`}
                        style={{ color: "white", marginLeft: 5 }}
                      >
                        Smiles
                      </label>
                      <br />
                      <input
                        id={`substrateChebiComment${TRid.split("")[2]}`}
                        type="radio"
                        value="substrateChebi"
                        name={`substrate${TRid.split("")[2]}`}
                        onClick={() =>
                          (document.getElementById(
                            `nobrSubstrate${TRid.split("")[2]}`
                          ).style.display = "block")
                        }
                      />
                      <label
                        for={`substrateChebiComment${TRid.split("")[2]}`}
                        style={{ color: "white", marginLeft: 5 }}
                      >
                        ChEBI
                      </label>
                      <br />
                      <input
                        id={`substrateImageComment${TRid.split("")[2]}`}
                        type="radio"
                        value="substrateImage"
                        name={`substrate${TRid.split("")[2]}`}
                        onClick={() =>
                          (document.getElementById(
                            `nobrSubstrate${TRid.split("")[2]}`
                          ).style.display = "block")
                        }
                      />
                      <label
                        for={`substrateImageComment${TRid.split("")[2]}`}
                        style={{ color: "white", marginLeft: 5 }}
                      >
                        Image
                      </label>
                      <br />

                      <nobr
                        id={`nobrSubstrate${TRid.split("")[2]}`}
                        style={{
                          display: "none",
                          borderTop: "1px white solid",
                        }}
                      >
                        <input
                          style={{ marginTop: 5 }}
                          id={`suggestInputComm${TRid.split("")[2]}${colID}`}
                          className="suggestInputComm"
                          placeholder="What to improve?"
                          maxLength={200}
                        />
                        <button
                          style={{ marginTop: 5 }}
                          id={`confirmButtonComm${TRid.split("")[2]}${colID}`}
                          className="confirmButtonComm"
                          onClick={() => {
                            commentFunction(TRid.split("")[2], colID);
                          }}
                        >
                          <div
                            id={`innerTextSubmittedComm${
                              TRid.split("")[2]
                            }${colID}`}
                            style={{ display: "none" }}
                          >
                            <FontAwesomeIcon icon={faCheck} color="green" />{" "}
                            Submitted
                          </div>{" "}
                          <div
                            id={`innerTextSubmitComm${
                              TRid.split("")[2]
                            }${colID}`}
                            style={{ display: "block" }}
                          >
                            Submit
                          </div>
                        </button>
                      </nobr>
                    </div>
                  </>
                ) : colID == 2 ? (
                  <>
                    <button
                      id="commentButtonProduct"
                      onClick={() => {
                        showComment(TRid.split("")[2], colID, rowCount);
                      }}
                    >
                      Comment
                    </button>
                    <div
                      id={`suggestDivComm${TRid.split("")[2]}${colID}`}
                      className="suggestDivComm"
                      align="left"
                    >
                      <p
                        style={{
                          color: "white",
                          borderBottom: "1px solid white",
                          marginBottom: 5,
                        }}
                      >
                        Choose an attribute to comment on:
                      </p>
                      <input
                        id={`productNameComment${TRid.split("")[2]}`}
                        type="radio"
                        value="productName"
                        name={`product${TRid.split("")[2]}`}
                        onClick={() =>
                          (document.getElementById(
                            `nobrProduct${TRid.split("")[2]}`
                          ).style.display = "block")
                        }
                      />
                      <label
                        for={`productNameComment${TRid.split("")[2]}`}
                        style={{ color: "white", marginLeft: 5 }}
                      >
                        Name
                      </label>
                      <br />
                      <input
                        id={`productSmilesComment${TRid.split("")[2]}`}
                        type="radio"
                        value="productSmiles"
                        name={`product${TRid.split("")[2]}`}
                        onClick={() =>
                          (document.getElementById(
                            `nobrProduct${TRid.split("")[2]}`
                          ).style.display = "block")
                        }
                      />
                      <label
                        for={`productSmilesComment${TRid.split("")[2]}`}
                        style={{ color: "white", marginLeft: 5 }}
                      >
                        Smiles
                      </label>
                      <br />
                      <input
                        id={`productChebiComment${TRid.split("")[2]}`}
                        type="radio"
                        value="productChebi"
                        name={`product${TRid.split("")[2]}`}
                        onClick={() =>
                          (document.getElementById(
                            `nobrProduct${TRid.split("")[2]}`
                          ).style.display = "block")
                        }
                      />
                      <label
                        for={`productChebiComment${TRid.split("")[2]}`}
                        style={{ color: "white", marginLeft: 5 }}
                      >
                        ChEBI
                      </label>
                      <br />
                      <input
                        id={`productImageComment${TRid.split("")[2]}`}
                        type="radio"
                        value="productImage"
                        name={`product${TRid.split("")[2]}`}
                        onClick={() =>
                          (document.getElementById(
                            `nobrProduct${TRid.split("")[2]}`
                          ).style.display = "block")
                        }
                      />
                      <label
                        for={`productImageComment${TRid.split("")[2]}`}
                        style={{ color: "white", marginLeft: 5 }}
                      >
                        Image
                      </label>
                      <br />

                      <nobr
                        id={`nobrProduct${TRid.split("")[2]}`}
                        style={{
                          display: "none",
                          borderTop: "1px white solid",
                        }}
                      >
                        <input
                          id={`suggestInputComm${TRid.split("")[2]}${colID}`}
                          className="suggestInputComm"
                          placeholder="What to improve?"
                          maxLength={200}
                          style={{ marginTop: 5 }}
                        />
                        <button
                          id={`confirmButtonComm${TRid.split("")[2]}${colID}`}
                          className="confirmButtonComm"
                          style={{ marginTop: 5 }}
                          onClick={() => {
                            commentFunction(TRid.split("")[2], colID);
                          }}
                        >
                          <div
                            id={`innerTextSubmittedComm${
                              TRid.split("")[2]
                            }${colID}`}
                            style={{ display: "none" }}
                          >
                            <FontAwesomeIcon icon={faCheck} color="green" />{" "}
                            Submitted
                          </div>{" "}
                          <div
                            id={`innerTextSubmitComm${
                              TRid.split("")[2]
                            }${colID}`}
                            style={{ display: "block" }}
                          >
                            Submit
                          </div>
                        </button>
                      </nobr>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}

            {val}
          </td>
        ))}
      </tr>
    );
  }
}

export default Table;
