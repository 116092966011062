export function clearAllInputs(setForm) {
    setForm((prev) => ({
      ...prev,
      name: "",
      genbank: "",
      uniprot: "",
      one: "",
      two: "",
      di: "",
      mono: "",
      tri: "",
      tetra: "",
      prenyl: "",
      sesq: "",
      sesquar: "",
      sester: "",
      hemi: "",
      animaliaMammal: "",
      animaliaCoral: "",
      animaliaBird: "",
      animaliaMammalHuman: "",
      animaliaInsect: "",
      archaea: "",
      bacteria: "",
      cyanobacteria: "",
      plantae: "",
      plantaeRedAlgae: "",
      amoebozoa: "",
      animaliaMarineSponge: "",
      fungi: "",
      viruses: "",
      reactChebi: "",
      reactName: "",
      reactSmiles: "",
      prodChebi: "",
      prodName: "",
      prodSmiles: "",
      intermediateSmiles: "",
      similarity: "",
      experiment: "",
      trivial: "",
      calculation: "",
    }));

    const idArray = [
      "MonoTerpInput",
      "SesqTerpInput",
      "TriTerpInput",
      "DiTerpInput",
      "SesterTerpInput",
      "SesquarTerpInput",
      "TetraTerpInput",
      "PrenylTerpInput",
      "HemiTerpInput",
      "ClassI",
      "ClassII",
      "AnimaliaMammalInput",
      "AnimaliaCoralInput",
      "AnimaliaBirdInput",
      "AnimaliaMarineSpongeInput",
      "AnimaliaMammalHumanInput",
      "AnimaliaInsectInput",
      "FungiInput",
      "BacteriaInput",
      "CyanobacteriaInput",
      "PlantaeRedAlgaeInput",
      "VirusesInput",
      "ArchaeaInput",
      "AmoebozoaInput",
      "PlantaeInput",
      "SimilarityEvidence",
      "ExperimentEvidence",
      "CalculationEvidence",
      "TrivialEvidence",
    ];
    for (let i = 0; i < idArray.length; i++) {
      let radioId = document?.getElementById(idArray[i]);
      if (radioId?.checked) {
        radioId.checked = false;
      }
    }
  }


export function clearEnzymeInputs(setForm) {
  setForm((prev) => ({
    ...prev,
    name: "",
    genbank: "",
    uniprot: "",
    one: "",
    two: "",
    di: "",
    mono: "",
    tri: "",
    tetra: "",
    prenyl: "",
    sesq: "",
    sesquar: "",
    sester: "",
    hemi: "",
    animaliaMammal: "",
    animaliaCoral: "",
    animaliaBird: "",
    animaliaMammalHuman: "",
    animaliaInsect: "",
    archaea: "",
    bacteria: "",
    cyanobacteria: "",
    plantae: "",
    plantaeRedAlgae: "",
    amoebozoa: "",
    animaliaMarineSponge: "",
    fungi: "",
    viruses: "",
  }));

  const idArray = [
    "MonoTerpInput",
    "SesqTerpInput",
    "TriTerpInput",
    "DiTerpInput",
    "SesterTerpInput",
    "SesquarTerpInput",
    "TetraTerpInput",
    "PrenylTerpInput",
    "HemiTerpInput",
    "ClassI",
    "ClassII",
    "AnimaliaMammalInput",
    "AnimaliaCoralInput",
    "AnimaliaBirdInput",
    "AnimaliaMarineSpongeInput",
    "AnimaliaMammalHumanInput",
    "AnimaliaInsectInput",
    "FungiInput",
    "BacteriaInput",
    "CyanobacteriaInput",
    "PlantaeRedAlgaeInput",
    "VirusesInput",
    "ArchaeaInput",
    "AmoebozoaInput",
    "PlantaeInput",
  ];
  for (let i = 0; i < idArray.length; i++) {
    let radioId = document.getElementById(idArray[i]);
    if (radioId.checked) {
      radioId.checked = false;
    }
  }
}


export function clearSubstrateInputs(setForm) {
  setForm((prev) => ({
    ...prev,
    reactChebi: "",
    reactName: "",
    reactSmiles: "",
  }));
}


export function clearProductInputs(setForm) {
  setForm((prev) => ({
    ...prev,
    prodChebi: "",
    prodName: "",
    prodSmiles: "",
  }));
}


export function clearMechanismInputs(setForm) {
  setForm((prev) => ({
    ...prev,
    intermediateSmiles: "",
    similarity: "",
    experiment: "",
    trivial: "",
    calculation: "",
  }));

  const idArray = [
    "SimilarityEvidence",
    "ExperimentEvidence",
    "CalculationEvidence",
    "TrivialEvidence",
  ];
  for (let i = 0; i < idArray.length; i++) {
    let radioId = document.getElementById(idArray[i]);
    if (radioId.checked) {
      radioId.checked = false;
    }
  }
}