import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Client/Home.js";
import SignUp from "./Client/signUp.js";
import LogIn from "./Client/Login.js";
import ReactionEntry from "./Client/ReactionEntry.js";
import MechanismEntry from "./Client/MechanismEntry.js";
import AdvancedSearch from "./Client/AdvancedSearch.js";
import MyComments from "./Client/MyComments.js";
import ErrorPage from "./Client/errorPage.js";
import RequireAuth from "./hooks/RequireAuth.js";
import PersistLogin from "./PersistLogin.js";
import NewMechanism from "./Client/NewMechanism.js";
import SuggestMechanism from "./Client/SuggestMechanism.js";
import SuggestReaction from "./Client/SuggestReaction.js";
import UserRoles from "./Client/UserRoles.js";
import MySuggestions from "./Client/MySuggestions.js";
import { useRouteError } from "react-router-dom";
import AllSuggestions from "./Client/AllSuggestions.js"

function App() {

  return (
    <>
      <div className="Routes">
        <Routes>
          <Route path="/*" element={<ErrorPage/>}/>
          <Route element={<PersistLogin />}>
            <Route path="/" element={<Home />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login" element={<LogIn />} />
            <Route path="/advancedsearch" element={<AdvancedSearch />} />
            <Route path="/userroles" element={<UserRoles/>}/>
            <Route element={<RequireAuth allowedRoles={[2001,1984,5150]}/>}>
              <Route path="/suggestmechanism" element={<SuggestMechanism/>}/>
              <Route path="/suggestreaction" element={<SuggestReaction/>}/>
              <Route path="/mysuggestions" element={<MySuggestions/>}/>
            </Route>
            <Route element={<RequireAuth allowedRoles={[5150]}/>}>
              <Route path="/suggestions" element={<AllSuggestions/>}/>
              <Route path="/reactionentry" element={<ReactionEntry />} />
              <Route path="/mechanismentry" element={<MechanismEntry />} />
              <Route path="/mycomments" element={<MyComments />} />
              <Route path="/newmechanism" element={<NewMechanism />} />
            </Route>
          </Route>
        </Routes>
      </div>
    </>
  );
}

export default App;
