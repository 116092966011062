import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

function NoResultsMessage({}) {
  return (
    <div id="noresults">
      <h1>
        <FontAwesomeIcon icon={faExclamationCircle} color="red" />
      </h1>
      <h1>No results found!</h1>
      <p>
        There are no reactions that match your current search. Try updating it
        for better results.{" "}
      </p>
    </div>
  );
}

export default NoResultsMessage;
