import "./AdvancedSearch.css";
import { useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Footer from "../Components/Footer.js";
import ReactionTable from "../reactionTable.js";
import useDidUpdateEffect from "../hooks/useDidUpdateEffect.js";
import HomeNavbar from "../HomeNavbar.js";
import PageCounter from "../Components/pageCounter/pageCounter.js";
import useAuth from "../hooks/useAuth.js";
import MechanismTable from "../mechanismTable.js";
import advancedSearch from "../api/advancedSearch.js";
import renderData from "../api/renderData.js";
import useSearchParamsState from "../hooks/useSearchParamsState.js";
import NoResultsMessage from "../Components/noResultsMessage.js";
import AdvancedFilters from "../Components/AdvancedSearch/advancedFilters.js";
import MechanismWindow from "../Components/MechanismTable/mechanismWindow.js";
import KingdomFilters from "../Components/AdvancedSearch/kingdomFilters.js";
import TypeFilters from "../Components/AdvancedSearch/typeFilters.js";
import ClassFilters from "../Components/AdvancedSearch/classFilters.js";
import CheckboxDiv from "../Components/AdvancedSearch/checkboxDiv.js";
import EvidenceFilters from "../Components/AdvancedSearch/evidenceFilters.js";
import SearchClearMechanismButtons from "../Components/AdvancedSearch/searchClearMechanismButtons.js";
import ResultBar from "../Components/AdvancedSearch/resultBar.js";
import FiltersMinimized from "../Components/AdvancedSearch/filtersMinimized.js";
import SmallLoading from "../Components/smallLoading.js";
import { showInput } from "../functions/showInputFunctions.js";
import useComment from "../hooks/useComment.js";
import {
  clearAllInputs,
  clearEnzymeInputs,
  clearMechanismInputs,
  clearProductInputs,
  clearSubstrateInputs,
} from "../functions/clearInputsFunctions.js";

function SearchReaction() {
  let [count, setCount] = useState(0);
  const [triggerUseEffect, setTriggerUseEffect] = useState(true);
  const [table, setTable] = useState([]);
  const [filtersVisible, setFiltersVisible] = useState(true);
  const [clearAll, setClearAll] = useState(false);
  const [mechanismUnclicked, setMechanismUnclicked] = useState(true);
  const [evidenceInputClicked, setEvidenceInputClicked] = useState(false);
  const [evidenceInputsUnclicked, setEvidenceInputsUnclicked] = useState(true);
  const [mechBody, setMechBody] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [pageCount, setPageCount] = useSearchParamsState("page", 1);
  const [buttonCount, setButtonCount] = useState(1);
  const [searchSubmit, setSearchSubmit] = useState(false);
  const [enzymeInputClicked, setEnzymeInputClicked] = useState(false);
  const [reactantInputClicked, setReactantInputClicked] = useState(false);
  const [productInputClicked, setProductInputClicked] = useState(false);
  const [enzymeInputUnclicked, setEnzymeInputUnclicked] = useState(true);
  const [reactantInputUnclicked, setReactantInputUnclicked] = useState(true);
  const [productInputUnclicked, setProductInputUnclicked] = useState(true);
  const [searchResult, setSearchResult] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [interactiveSearchSwitch, setInteractiveSearchSwitch] = useState(false)
  const [searchParams] = useSearchParams()
  const [form, setForm] = useSearchParamsState("filters", {
    animaliaMammal: "",
    animaliaCoral: "",
    animaliaBird: "",
    animaliaMarineSponge: "",
    animaliaInsect: "",
    animaliaMammalHuman: "",
    fungi: "",
    amoebozoa: "",
    plantae: "",
    bacteria: "",
    cyanobacteria: "",
    plantaeRedAlgae: "",
    viruses: "",
    archaea: "",
    di: "",
    mono: "",
    sesq: "",
    tri: "",
    tetra: "",
    sester: "",
    sesquar: "",
    prenyl: "",
    hemi: "",
    one: "",
    two: "",
    name: "",
    similarity: "",
    experiment: "",
    trivial: "",
    calculation: "",
    uniprot: "",
    genbank: "",
    reactSmiles: "",
    prodSmiles: "",
    reactName: "",
    prodName: "",
    reactChebi: "",
    prodChebi: "",
    intermediateSmiles: "",
  });

  const { auth } = useAuth();
  const submitSuggestComm = useComment();
  
  const isQuickSearch = false;
  const enzymeInputs = [
    "NameInput",
    "TypeInput",
    "ClassInput",
    "UniprotInput",
    "GenbankInput",
    "KingdomInput",
  ];
  const reactantInputs = [
    "ReactantSMILESInput",
    "ReactantChebiInput",
    "ReactantNameInput",
  ];
  const productInputs = [
    "ProductSMILESInput",
    "ProductChebiInput",
    "ProductNameInput",
  ];

  function reloadAdvanced() {
      clearAllInputs(setForm);
      setClearAll((current) => !current);
      if (pageCount !== 1) {
        setInteractiveSearchSwitch((current) => !current);
      }
  }

  function showFilters() {
    setFiltersVisible(true);
    document.getElementById("sectionFilters").style.display = "block";
  }

  function hideFilters() {
    setFiltersVisible(false);
    document.getElementById("sectionFilters").style.display = "none";
  }

  function handleSearch(event) {
    event.preventDefault();
    if (pageCount == 1) {
      setTriggerUseEffect((current) => !current);
    } else {
      setInteractiveSearchSwitch((current) => !current);
    }
  }

  function handleClear(event) {
    event.preventDefault();
    clearAllInputs(setForm);
    if (pageCount == 1) {
      setClearAll((current) => !current);
    } else {
      setInteractiveSearchSwitch((current) => !current);
    }
  }

  function updateForm(event) {
    setForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  }

  function handleClearEnzymeInputs(event) {
    event.preventDefault();
    clearEnzymeInputs(setForm);
    setInteractiveSearchSwitch((current) => !current);
    setClearAll((current) => !current);
  }

  function handleClearSubstrateInputs(event) {
    event.preventDefault();
    clearSubstrateInputs(setForm);
    setInteractiveSearchSwitch((current) => !current);
    setClearAll((current) => !current);
  }

  function handleClearProductInputs(event) {
    event.preventDefault();
    clearProductInputs(setForm);
    setInteractiveSearchSwitch((current) => !current);
    setClearAll((current) => !current);
  }

  function handleClearEvidenceInputs(event) {
    event.preventDefault();
    clearMechanismInputs(setForm);
    setInteractiveSearchSwitch((current) => !current);
    setClearAll((current) => !current);
  }

  function showEnzymePopups() {
    if (!enzymeInputClicked) {
      showInput(
        enzymeInputs,
        "enzymeDD",
        enzymeInputClicked,
        setEnzymeInputClicked,
        "EnzymeInputs",
        setEnzymeInputUnclicked
      );
    }
  }

  function showEnzymeInput() {
    showInput(
      enzymeInputs,
      "enzymeDD",
      enzymeInputClicked,
      setEnzymeInputClicked,
      "EnzymeInputs",
      setEnzymeInputUnclicked
    );
  }

  function showSubstratePopups() {
    if (!reactantInputClicked) {
      showInput(
        reactantInputs,
        "reactantDD",
        reactantInputClicked,
        setReactantInputClicked,
        "ReactantInputs",
        setReactantInputUnclicked
      );
    }
  }

  function showSubstrateInput() {
    showInput(
      reactantInputs,
      "reactantDD",
      reactantInputClicked,
      setReactantInputClicked,
      "ReactantInputs",
      setReactantInputUnclicked
    );
  }

  function showProductPopups() {
    if (!productInputClicked) {
      showInput(
        productInputs,
        "productDD",
        productInputClicked,
        setProductInputClicked,
        "ProductInputs",
        setProductInputUnclicked
      );
    }
  }

  function showProductInput() {
    showInput(
      productInputs,
      "productDD",
      productInputClicked,
      setProductInputClicked,
      "ProductInputs",
      setProductInputUnclicked
    );
  }

  function showMechanismPopups() {
    setMechanismUnclicked(false);
    if (!evidenceInputClicked) {
      showInput(
        ["EvidenceRadioInput", "IntermediateSmilesInput", "EvidenceSpan"],
        "mechanismDD",
        evidenceInputClicked,
        setEvidenceInputClicked,
        "EvidenceInput",
        setEvidenceInputsUnclicked
      );
    }
  }

  function showEvidenceInput() {
    showInput(
      ["EvidenceRadioInput", "IntermediateSmilesInput"],
      "mechanismDD",
      evidenceInputClicked,
      setEvidenceInputClicked,
      "EvidenceInput",
      setEvidenceInputsUnclicked
    );
  }

  function handleToggleMechanism(event) {
    event.preventDefault();
    clearMechanismInputs(setForm);
    const showInput = document.getElementById("EvidenceSpan");
    if (showInput.style.display === "none") {
      showInput.style.display = "flex";
      setMechanismUnclicked(false);
    } else {
      showInput.style.display = "none";
      setMechanismUnclicked(true);
    }
    if (pageCount === 1) {
      setTriggerUseEffect((current) => !current);
    } else {
      setInteractiveSearchSwitch((current) => !current);
    }
  }

  async function interactiveAdvancedSearch(
    showSpecificPopups,
    key,
    value,
    idInput
  ) {
    try {
      showSpecificPopups();
      if (idInput) {
        document.getElementById(idInput).checked = true;
      }
      setForm((prev) => ({
        ...prev,
        [key]: value,
      }));
      if (pageCount == 1) {
        setTriggerUseEffect((current) => !current);
      } else {
        setInteractiveSearchSwitch((current) => !current);
      }
    } catch (err) {
      throw err;
    }
  }

  async function loadData() {
    const result = await advancedSearch(
      form,
      pageCount,
      buttonCount,
      setIsLoading,
      setSearchSubmit,
      setDataCount,
      mechanismUnclicked
    );
    const table = result;
    const body = await renderData({
      table,
      isQuickSearch,
      interactiveAdvancedSearch,
      showEnzymePopups,
      showMechanismPopups,
      showProductPopups,
      showSubstratePopups,
      setMechBody,
      mechanismUnclicked
    });
    setSearchResult(body);
    setSearchSubmit(true);
    setIsLoading(false);
    setTable(result);
  }

  useEffect(() => {
    loadData();
  }, [pageCount, clearAll, triggerUseEffect]);

  useDidUpdateEffect(() => {
    setButtonCount(1);
    setPageCount(1);
  }, [interactiveSearchSwitch]);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css"
          rel="stylesheet"
        />
      </head>
      <body id="body">
        <HomeNavbar reloadAdvanced={reloadAdvanced} />
        <section id="sectionFilters">
          <div style={{ paddingTop: 10 }} id="content">
            <div id="filtersMargins">
              <form
                id="inputs"
                onSubmit={(e) => {
                  e.preventDefault();
                  loadData();
                }}
              >
                <div id="filters">
                  <ul style={{ listStyle: "none" }}>
                    <AdvancedFilters
                      id={"EnzymeInputs"}
                      idDD={"enzymeDD"}
                      unclickedState={enzymeInputUnclicked}
                      handleShowInputs={showEnzymeInput}
                      handleClearFilters={handleClearEnzymeInputs}
                    >
                      Enzyme filters:
                    </AdvancedFilters>

                    <div id="allEnzymeInputs">
                      <input
                        id="NameInput"
                        placeholder="Enzyme name"
                        name="name"
                        style={{ display: "none" }}
                        onChange={updateForm}
                        value={form.name}
                      />
                      <CheckboxDiv id={"KingdomInput"}>
                        <KingdomFilters updateForm={updateForm} searchParams={searchParams}/>
                      </CheckboxDiv>
                      <CheckboxDiv id={"TypeInput"}>
                        <TypeFilters updateForm={updateForm} searchParams={searchParams}/>
                      </CheckboxDiv>
                      <CheckboxDiv id={"ClassInput"}>
                        <ClassFilters updateForm={updateForm} searchParams={searchParams}/>
                      </CheckboxDiv>
                      <input
                        id="UniprotInput"
                        placeholder="Uniprot ID"
                        style={{ display: "none" }}
                        name="uniprot"
                        onChange={updateForm}
                        value={form.uniprot}
                      />
                      <input
                        id="GenbankInput"
                        placeholder="Genbank ID"
                        style={{ display: "none" }}
                        name="genbank"
                        onChange={updateForm}
                        value={form.genbank}
                      />
                    </div>
                    <AdvancedFilters
                      id={"ReactantInputs"}
                      idDD={"reactantDD"}
                      unclickedState={reactantInputUnclicked}
                      handleShowInputs={showSubstrateInput}
                      handleClearFilters={handleClearSubstrateInputs}
                    >
                      Substrate filters:
                    </AdvancedFilters>
                    <textarea
                      id="ReactantSMILESInput"
                      placeholder="Substrate SMILES"
                      style={{ display: "none" }}
                      name="reactSmiles"
                      onChange={updateForm}
                      value={form.reactSmiles}
                    />
                    <input
                      id="ReactantNameInput"
                      placeholder="Substrate name"
                      style={{ display: "none" }}
                      name="reactName"
                      onChange={updateForm}
                      value={form.reactName}
                    />
                    <input
                      id="ReactantChebiInput"
                      placeholder="Substrate ChEBI"
                      style={{ display: "none" }}
                      name="reactChebi"
                      onChange={updateForm}
                      value={form.reactChebi}
                    />
                    <AdvancedFilters
                      id={"ProductInputs"}
                      idDD={"productDD"}
                      unclickedState={productInputUnclicked}
                      handleShowInputs={showProductInput}
                      handleClearFilters={handleClearProductInputs}
                    >
                      Product filters:
                    </AdvancedFilters>

                    <textarea
                      id="ProductSMILESInput"
                      placeholder="Product SMILES"
                      style={{ display: "none" }}
                      name="prodSmiles"
                      onChange={updateForm}
                      value={form.prodSmiles}
                    />
                    <input
                      id="ProductNameInput"
                      placeholder="Product name"
                      style={{ display: "none" }}
                      name="prodName"
                      onChange={updateForm}
                      value={form.prodName}
                    />
                    <input
                      id="ProductChebiInput"
                      placeholder="Product ChEBI"
                      style={{ display: "none" }}
                      name="prodChebi"
                      onChange={updateForm}
                      value={form.prodChebi}
                    />
                    <span
                      id="EvidenceSpan"
                      style={
                        mechanismUnclicked
                          ? { display: "none" }
                          : { display: "block" }
                      }
                    >
                      <AdvancedFilters
                        id={"EvidenceInput"}
                        idDD={"mechanismDD"}
                        unclickedState={evidenceInputsUnclicked}
                        handleShowInputs={showEvidenceInput}
                        handleClearFilters={handleClearEvidenceInputs}
                      >
                        Mechanism filters:
                      </AdvancedFilters>
                    </span>
                    <div
                      id="IntermediateSmilesInput"
                      style={
                        mechanismUnclicked
                          ? { display: "none" }
                          : !evidenceInputClicked
                          ? { display: "none" }
                          : { display: "flex" }
                      }
                    >
                      <textarea
                        placeholder="Intermediate SMILES"
                        id="IntermediateSMILESInput"
                        name="intermediateSmiles"
                        onChange={updateForm}
                        value={form.intermediateSmiles}
                      />
                    </div>
                    <div
                      id="EvidenceRadioInput"
                      style={
                        mechanismUnclicked
                          ? { display: "none", columnCount: 3 }
                          : !evidenceInputClicked
                          ? { display: "none", columnCount: 3 }
                          : { display: "flex", columnCount: 3 }
                      }
                      class="row"
                    >
                      <EvidenceFilters updateForm={updateForm} searchParams={searchParams}/>
                    </div>
                    <SearchClearMechanismButtons
                      handleClear={handleClear}
                      handleSearch={handleSearch}
                      handleToggleMechanism={handleToggleMechanism}
                      mechanismUnclicked={mechanismUnclicked}
                    />
                  </ul>
                </div>
                <ResultBar
                  isLoading={isLoading}
                  filtersVisible={filtersVisible}
                  dataCount={dataCount}
                  searchSubmit={searchSubmit}
                  hideFilters={hideFilters}
                />
              </form>
            </div>
          </div>
        </section>
        <FiltersMinimized
          filtersVisible={filtersVisible}
          dataCount={dataCount}
          showFilters={showFilters}
        />

        <section id="tableSection">
          <div id="table" style={{ paddingTop: 50 }}>
            {!searchSubmit ? (
              <SmallLoading />
            ) : searchResult.length == 0 ? (
              <NoResultsMessage />
            ) : (
              <>
                <ReactionTable
                  body={searchResult}
                  display={"none"}
                  commentFunction={submitSuggestComm}
                  auth={auth}
                />
              </>
            )}
          </div>
        </section>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ul
            style={{
              listStyle: "none",
              padding: 0,
              display: "flex",
              justifyContent: "space-between",
              marginTop: 50,
            }}
          >
            <li></li>
            <li>
              <PageCounter
                dataCount={dataCount}
                buttonCount={buttonCount}
                setButtonCount={setButtonCount}
                pageCount={pageCount}
                setPageCount={setPageCount}
                setCount={setCount}
                count={count}
              />
            </li>
            <li></li>
          </ul>
        </div>
        <div id="footer">
          <Footer />
        </div>
      </body>
      <MechanismWindow>
        <MechanismTable
          heading={[
            "Intermediate substrate",
            "Intermediate product",
            "Mechanism type",
          ]}
          body={mechBody}
        />
      </MechanismWindow>
    </html>
  );
}

export default SearchReaction;
