import React, { useState } from "react";
import { useEffect } from "react";
import "./ReactionEntry.css";
import Footer from "../Components/Footer.js";
import {
  faExclamationCircle,
  faCheck,
  faAsterisk,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth.js";
import HomeNavbar from "../HomeNavbar.js";
import encoding from "encoding";
import { noButton, submitOK } from "../functions/helperSuggestFunction.js";
import TextAreaMandatory from "../Components/Suggestions/textAreaMandatory.js";
import TextInputMandatory from "../Components/Suggestions/textInputMandatory.js";
import TextInputOptional from "../Components/Suggestions/textInputOptional.js";
import TypeFilters from "../Components/AdvancedSearch/typeFilters.js";
import ClassFilters from "../Components/AdvancedSearch/classFilters.js";
import KingdomFilters from "../Components/AdvancedSearch/kingdomFilters.js";

function Reaction() {
  const { state } = useLocation();

  const [validChebiReactant, setValidChebiReactant] = useState(true);
  const [validChebiProduct, setValidChebiProduct] = useState(true);
  const [validChebiCosubstrate, setValidChebiCosubstrate] = useState(true);
  const [noteLength, setNoteLength] = useState(0);
  const [rejectNote, setRejectNote] = useState("");
  const [response, setResponse] = useState("");
  const [RE_pair, setRE_pair] = useState(state?.RE_pair ? state.RE_pair : "");
  const [form, setForm] = useState({
    animaliaMammal: state?.kingdom === "Animalia (Mammal)" ? state.kingdom : "",
    animaliaCoral: state?.kingdom === "Animalia (Coral)" ? state.kingdom : "",
    animaliaBird: state?.kingdom === "Animalia (Bird)" ? state.kingdom : "",
    animaliaMarineSponge:
      state?.kingdom === "Animalia (Marine Sponge)" ? state.kingdom : "",
    animaliaInsect: state?.kingdom === "Animalia (Insect)" ? state.kingdom : "",
    animaliaMammalHuman:
      state?.kingdom === "Animalia (Mammal, Human)" ? state.kingdom : "",
    fungi: state?.kingdom === "Fungi" ? state.kingdom : "",
    amoebozoa: state?.kingdom === "Amoebozoa" ? state.kingdom : "",
    plantae: state?.kingdom === "Plantae" ? state.kingdom : "",
    bacteria: state?.kingdom === "Bacteria" ? state.kingdom : "",
    cyanobacteria: state?.kingdom === "Cyanobacteria" ? state.kingdom : "",
    plantaeRedAlgae:
      state?.kingdom === "Plantae (Red algae)" ? state.kingdom : "",
    viruses: state?.kingdom === "Viruses" ? state.kingdom : "",
    archaea: state?.kingdom === "Archaea" ? state.kingdom : "",
    di: state?.type === "di" ? state.type : "",
    mono: state?.type === "mono" ? state.type : "",
    sesq: state?.type === "sesq" ? state.type : "",
    tri: state?.type === "tri" ? state.type : "",
    tetra: state?.type === "tetra" ? state.type : "",
    sester: state?.type === "sester" ? state.type : "",
    sesquar: state?.type === "sesquar" ? state.type : "",
    prenyl: state?.type === "pt" ? state.type : "",
    hemi: state?.type === "hemi" ? state.type : "",
    one: state?.class == 1 ? state.class : "",
    two: state?.class == 2 ? state.class : "",
    species: state?.species ? state.species : "",
    enzymeName: state?.enzyme_name ? state.enzyme_name : "",
    AASequence: state?.aminoacid_sequence ? state.aminoacid_sequence : "",
    uniprot: state?.uniprot_id ? state.uniprot_id : "",
    genbank: state?.genbank_id ? state.genbank_id : "",
    reactSmiles: state?.substrate_smiles ? state.substrate_smiles : "",
    prodSmiles: state?.product_smiles ? state.product_smiles : "",
    reactName: state?.substrate_name ? state.substrate_name : "",
    prodName: state?.product_name ? state.product_name : "",
    reactChebi: state?.substrate_chebi ? state.substrate_chebi : "",
    prodChebi: state?.product_chebi ? state.product_chebi : "",
    cosubstrateName: state?.cosubstrate_name ? state.cosubstrate_name : "",
    cosubstrateChebi: state?.cosubstrate_chebi ? state.cosubstrate_chebi : "",
    cosubstrateSmiles: state?.cosubstrate_smiles
      ? state.cosubstrate_smiles
      : "",
    link: state?.publication_link ? state.publication_link : "",
    pubmedId: state?.publication_pmid ? state.publication_pmid : "",
    doi: state?.publication_doi ? state.publication_doi : "",
  });

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const { auth } = useAuth();
  const editor = [5150, 1984];

  const typeInputsID = [
    ["MonoTerpInput", "mono"],
    ["SesqTerpInput", "sesq"],
    ["TriTerpInput", "tri"],
    ["TetraTerpInput", "tetra"],
    ["DiTerpInput", "di"],
    ["SesterTerpInput", "sester"],
    ["SesquarTerpInput", "sesquar"],
    ["PrenylTerpInput", "pt"],
    ["HemiTerpInput", "hemi"],
  ];
  const classInputsID = [
    ["ClassI", 1],
    ["ClassII", 2],
  ];
  const kingdomInputsID = [
    ["PlantaeInput", "Plantae"],
    ["AnimaliaCoralInput", "Animalia (Coral)"],
    ["BacteriaInput", "Bacteria"],
    ["FungiInput", "Fungi"],
    ["AnimaliaBirdInput", "Animalia (Bird)"],
    ["AnimaliaMammalInput", "Animalia (Mammal)"],
    ["AnimaliaMammalHumanInput", "Animalia (Mammal, Human)"],
    ["ArchaeaInput", "Archaea"],
    ["AmoebozoaInput", "Amoebozoa"],
    ["AnimaliaMarineSpongeInput", "Animalia (Marine Sponge)"],
    ["PlantaeRedAlgaeInput", "Plantae (Red algae)"],
    ["VirusesInput", "Viruses"],
    ["AnimaliaInsectInput", "Animalia (Insect)"],
    ["CyanobacteriaInput", "Cyanobacteria"],
  ];

  const CHEBI_REGEX = /^[0-9]{0,50}$/;

  useEffect(() => {
    setValidChebiReactant(CHEBI_REGEX.test(form.reactChebi));
  }, [form.reactChebi]);

  useEffect(() => {
    setValidChebiCosubstrate(CHEBI_REGEX.test(form.cosubstrateChebi));
  }, [form.cosubstrateChebi]);

  useEffect(() => {
    setValidChebiProduct(CHEBI_REGEX.test(form.prodChebi));
  }, [form.prodChebi]);

  useEffect(() => {
    typeInputsID.forEach((element) => {
      if (state?.type === element[1]) {
        document.getElementById(element[0]).checked = true;
      }
    });

    classInputsID.forEach((element) => {
      if (state?.class === element[1]) {
        document.getElementById(element[0]).checked = true;
      }
    });

    kingdomInputsID.forEach((element) => {
      if (state?.kingdom === element[1]) {
        document.getElementById(element[0]).checked = true;
      }
    });
  }, []);

  function updateForm(event) {
    setForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  }

  async function rejectSuggestion(event) {
    event.preventDefault();
    try {
      await axiosPrivate.post("/rejectsuggestion", {
        suggestion_id: state.suggestion_id,
      });
    } catch (err) {
      throw err;
    } finally {
      const elements = document.querySelectorAll("#body > *:not(#overlap)");
      elements.forEach((element) => {
        element.style.filter = "blur(3px)";
      });
      document.getElementById("submitConfirmation").style.display = "none";
      document.getElementById("overlap").style.display = "block";
      document.getElementById("rejectNote").style.display = "block";
    }
  }

  async function feedbackYes() {
    document.getElementById("rejectNote").style.display = "none";
    document.getElementById("RejectNote").style.display = "block";
  }

  async function feedbackNo() {
    document.getElementById("overlap").style.display = "none";
    document.getElementById("rejectNote").style.display = "none";
    navigate("/suggestions");
  }

  async function submitNote() {
    try {
      await axiosPrivate.post("/rejectsuggestion", {
        suggestion_id: state.suggestion_id,
        rejectNote: rejectNote,
      });
    } catch (err) {
      throw err;
    } finally {
      navigate("/suggestions");
    }
  }

  async function submitEntry(event) {
    event.preventDefault();
    const username = auth.username;
    try {
      const response = await axiosPrivate.post("/reactionentry", {
        form: {
          ...form,
          reactSmiles: encoding.convert(form.reactSmiles, "Latin_1"),
          prodSmiles: encoding.convert(form.prodSmiles, "Latin_1"),
          cosubstrateSmiles: encoding.convert(
            form.cosubstrateSmiles,
            "Latin_1"
          ),
        },
        username,
        suggestion_id: state?.suggestion_id ? state.suggestion_id : "",
      });
      setRE_pair(response.data.RE_pair[0].RE_pair);
      setResponse(response.data.duplication);

      if (!response.data.duplication) {
        const elements = document.querySelectorAll("#body > *:not(#overlap)");
        elements.forEach((element) => {
          element.style.filter = "blur(3px)";
        });
        document.getElementById("overlap").style.display = "block";
        document.getElementById("submitConfirmation").style.display = "block";
      } else {
        const elements = document.querySelectorAll("#body > *:not(#overlap)");
        elements.forEach((element) => {
          element.style.filter = "blur(3px)";
        });
        document.getElementById("overlap").style.display = "block";
        document.getElementById("duplication").style.display = "block";
      }
    } catch (err) {
      console.error(err);
    }
  }

  async function yesButton() {
    document.getElementById("addMechanism").style.display = "none";
    navigate("/newmechanism", {
      state: {
        RE_pair: RE_pair,
        enzyme_name: form.enzymeName,
        substrate_smiles: form.reactSmiles,
        product_smiles: form.prodSmiles,
        substrate_name: form.reactName,
        product_name: form.prodName,
        product_chebi: form.productChebi,
      },
    });
  }

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css"
          rel="stylesheet"
        />
      </head>
      <body id="body">
        <HomeNavbar />
        <div class="row" id="entrycontent" style={{ paddingTop: "100px" }}>
          <div class="col-lg-12" align="center">
            <div class="row">
              <div class="col-lg-12">
                <form id="ReactionInputs">
                  <div style={{ position: "relative" }}>
                    <div align="left" id="PubForm" style={{ zIndex: 3 }}>
                      <h5>
                        <FontAwesomeIcon icon={faAsterisk} color="red" />{" "}
                        Publication:
                      </h5>
                      <TextInputOptional
                        label={"Publication Link:"}
                        inputId={"EnzymePublicationLink"}
                        name={"link"}
                        updateForm={updateForm}
                        value={form.link}
                      />
                      <TextInputOptional
                        label={"Pub Med ID:"}
                        inputId={"EnzymePMID"}
                        name={"pubmedId"}
                        updateForm={updateForm}
                        value={form.pubmedId}
                      />
                      <TextInputOptional
                        label={"Publication DOI:"}
                        inputId={"EnzymePublicationDOI"}
                        name={"doi"}
                        updateForm={updateForm}
                        value={form.doi}
                      />
                    </div>
                  </div>
                  <div style={{ position: "relative" }}>
                    <div id="FullForm">
                      <h5 align="left">Enzyme:</h5>
                      <div id="enzymeInputs" align="left">
                        <TextInputOptional
                          label={"Name:"}
                          inputId={"EnzymeName"}
                          name={"enzymeName"}
                          updateForm={updateForm}
                          value={form.enzymeName}
                        />
                        <TextAreaMandatory
                          label={"Aminoacid sequence:"}
                          inputId={"EnzymeAASequence"}
                          name={"AASequence"}
                          updateForm={updateForm}
                          value={form.AASequence}
                        />

                        <label for="KingdomRadioInputs">Kingdom:</label>
                        <div
                          id="KingdomRadioInputs"
                          style={{ display: "block", columnCount: 3 }}
                          class="row"
                        >
                          <KingdomFilters updateForm={updateForm} />
                        </div>
                        <TextInputMandatory
                          label={"Species:"}
                          inputId={"EnzymeSpecies"}
                          name={"species"}
                          updateForm={updateForm}
                          value={form.species}
                        />
                        <label for="ClassRadioInputs">Class:</label>
                        <div
                          id="ClassRadioInputs"
                          style={{ display: "block", columnCount: 3 }}
                          class="row"
                        >
                          <ClassFilters updateForm={updateForm} />
                        </div>
                        <TextInputOptional
                          label={"Uniprot ID:"}
                          inputId={"EnzymeUniprot"}
                          name={"uniprot"}
                          updateForm={updateForm}
                          value={form.uniprot}
                        />
                        <TextInputOptional
                          label={"Genbank ID:"}
                          inputId={"EnzymeGenbank"}
                          name={"genbank"}
                          updateForm={updateForm}
                          value={form.genbank}
                        />

                        <label for="TypeRadioInputs">Type:</label>
                        <div
                          id="TypeRadioInputs"
                          style={{ display: "block", columnCount: 3 }}
                          class="row"
                        >
                          <TypeFilters updateForm={updateForm} />
                        </div>
                      </div>
                      <div id="Reactant" align="left">
                        <h5>Substrate:</h5>
                      </div>
                      <div id="reactantInputs" align="left">
                        <TextInputOptional
                          label={"Name:"}
                          inputId={"ReactantName"}
                          name={"reactName"}
                          updateForm={updateForm}
                          value={form.reactName}
                        />
                        <TextInputOptional
                          label={"ChEBI:"}
                          inputId={"ReactantChebi"}
                          name={"reactChebi"}
                          updateForm={updateForm}
                          value={form.reactChebi}
                        />
                        <TextAreaMandatory
                          label={"SMILES:"}
                          inputId={"ReactantSMILES"}
                          name={"reactSmiles"}
                          updateForm={updateForm}
                          value={form.reactSmiles}
                        />
                      </div>
                      <div id="Reactant" align="left">
                        <h5>Cosubstrate:</h5>
                      </div>
                      <div id="reactantInputs" align="left">
                        <TextInputOptional
                          label={"Name:"}
                          inputId={"ReactantName"}
                          name={"cosubstrateName"}
                          updateForm={updateForm}
                          value={form.cosubstrateName}
                        />
                        <TextInputOptional
                          label={"ChEBI:"}
                          inputId={"ReactantChebi"}
                          name={"cosubstrateChebi"}
                          updateForm={updateForm}
                          value={form.cosubstrateChebi}
                        />
                        <label for="ReactantSMILES">SMILES:</label>
                        <textarea
                          id="ReactantSMILES"
                          name="cosubstrateSmiles"
                          onChange={updateForm}
                          value={form.cosubstrateSmiles}
                        />
                      </div>
                      <div id="Product" align="left">
                        <h5>Product:</h5>
                      </div>
                      <div id="productInputs" align="left">
                        <TextInputOptional
                          label={"Name:"}
                          inputId={"ProductName"}
                          name={"prodName"}
                          updateForm={updateForm}
                          value={form.prodName}
                        />
                        <TextInputOptional
                          label={"ChEBI:"}
                          inputId={"ProductChebi"}
                          name={"prodChebi"}
                          updateForm={updateForm}
                          value={form.prodChebi}
                        />
                        <TextAreaMandatory
                          label={"SMILES:"}
                          inputId={"ProductSMILES"}
                          name={"prodSmiles"}
                          updateForm={updateForm}
                          value={form.prodSmiles}
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: 10, marginBottom: 10 }}>
                    <ul
                      style={{
                        listStyle: "none",
                        padding: 0,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <li>
                        <button
                          id="confirmButton"
                          onClick={submitEntry}
                          disabled={
                            !validChebiCosubstrate ||
                            !validChebiProduct ||
                            !validChebiReactant ||
                            !form.AASequence ||
                            !form.species ||
                            !(form.link || form.doi || form.pubmedId) ||
                            !form.reactSmiles ||
                            !form.prodSmiles
                              ? true
                              : false
                          }
                          style={{
                            opacity:
                              !validChebiCosubstrate ||
                              !validChebiProduct ||
                              !validChebiReactant ||
                              !form.AASequence ||
                              !form.species ||
                              !(form.link || form.doi || form.pubmedId) ||
                              !form.reactSmiles ||
                              !form.prodSmiles
                                ? "0.5"
                                : "1",
                          }}
                        >
                          Submit
                        </button>
                      </li>
                      <li
                        style={
                          editor.includes(auth?.roles) &&
                          state?.from === "/suggestions"
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <button id="confirmButton" onClick={rejectSuggestion}>
                          Reject
                        </button>
                      </li>
                    </ul>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div id="entryfooter">
          <Footer />
        </div>
      </body>

      <div id="overlap" style={{ display: "none" }}>
        <div
          id="rejectNote"
          align="left"
          style={{ display: "none", width: "50%", zIndex: 5 }}
        >
          <ul>
            <li>
              <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />{" "}
              Suggestion has been successfully rejected. Would you like to
              provide a feedback?
            </li>
            <li>
              <button id="yes" onClick={feedbackYes}>
                Yes
              </button>
            </li>
            <li>
              <button id="no" onClick={feedbackNo}>
                No
              </button>
            </li>
          </ul>
        </div>

        <div id="RejectNote" style={{ display: "none" }}>
          <ul>
            <li>
              <label for="mechanisminput">
                <>Post a feedback: </>
              </label>
              <textarea
                placeholder="Feedback note"
                id="mechanisminput"
                value={rejectNote}
                maxLength={500}
                onChange={(event) => {
                  setRejectNote(event.target.value);
                  setNoteLength(event.target.value.split("").length);
                }}
              />
              <p style={{ fontSize: 14 }} align="left">
                {noteLength}/500
              </p>
            </li>
            <li>
              <button id="confirmButton" onClick={submitNote}>
                Submit
              </button>
            </li>
          </ul>
        </div>

        <div id="submitConfirmation" align="left" style={{ display: "none" }}>
          <ul>
            <li>
              <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} /> Your
              entry has been successfully submitted to the database.
            </li>
            <li>
              <button id="ok" onClick={submitOK}>
                OK
              </button>
            </li>
          </ul>
        </div>
        <div id="duplication" align="left" style={{ display: "none" }}>
          <ul>
            <li>
              <FontAwesomeIcon
                icon={faExclamationCircle}
                style={{ color: "red" }}
              />{" "}
              Your entry contained one or more duplicate values.<br></br>
              <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />{" "}
              Duplicates were not inserted but your entry remains valid.
            </li>
            <li>
              <button id="ok" onClick={submitOK}>
                OK
              </button>
            </li>
          </ul>
        </div>
        <div id="addMechanism" align="left" style={{ display: "none" }}>
          <ul>
            <li>Do you want to enter a mechanism for this reaction?</li>
            <li>
              <button id="yes" onClick={yesButton}>
                Yes
              </button>
              <button id="no" onClick={() => noButton(setForm)}>
                No
              </button>
            </li>
          </ul>
        </div>
      </div>
    </html>
  );
}

export default Reaction;
