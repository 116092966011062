import axios from "./axios.js";
import { Buffer } from "buffer";


async function quickSearch(
  seeAllReactions,
  setIsLoading,
  count,
  onlyMechanism,
  quickSearchValue,
  pageCount,
  setDataCount,
) {

  try {
    setIsLoading(true);
    let response = "";

    if (!seeAllReactions) {
      response = await axios.post("/display", {
        count,
        onlyMechanism,  
        quickSearchValue: quickSearchValue != "" ? Buffer.from(quickSearchValue) : quickSearchValue,
        seeAllReactions,
        searchLimit: 1,
        pageCount: count + 1,
      });
    } else {
      response = await axios.post("/display", {
        pageCount,
        onlyMechanism,
        quickSearchValue: quickSearchValue != "" ? Buffer.from(quickSearchValue) : quickSearchValue,
        count,
        seeAllReactions,
        searchLimit: 10,
      });
    }

    const table = response?.data;

    if (table?.length > 0) {
      setDataCount(table[0].count);
    } else {
      setDataCount(0);
    }
    return {table:table}
  } catch (err) {
    throw err
  } finally {
    setIsLoading(false)
  }
}


export default quickSearch;
