import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faCheck } from "@fortawesome/free-solid-svg-icons";

function CopySequence({ table, row }) {
  function copySequence(id) {
    let sequence = document.getElementById(id).innerText;
    let elem = document.createElement("textarea");
    document.body.appendChild(elem);
    sequence = sequence.trim();
    elem.value = sequence;
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
  }

  function handleMouseEnter(idDiv) {
    const popupElement = document.getElementById(idDiv);
    popupElement.style.display = "block";
  }

  function handleMouseLeave(idDivCopy, idDivCopied) {
    document.getElementById(idDivCopy).style.display = "none";
    document.getElementById(idDivCopied).style.display = "none";
  }

  function handleClickSequence(idDivCopy, idDivCopied) {
    copySequence(`sequence${row}`);
    document.getElementById(idDivCopy).style.display = "none";
    document.getElementById(idDivCopied).style.display = "block";
  }

  return (
    <ul
      style={{
        display: "flex",
        listStyle: "none",
        padding: 0,
        justifyContent: "center",
        margin: "0 auto",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
      }}
    >
      {table[row].aminoacid_sequence ? (
        <>
          <li style={{ position: "relative" }}>
            Sequence:{" "}
            <button
              id="sequence"
              style={{ border: "none", backgroundColor: "rgb(245,245,245)" }}
              onClick={() =>
                handleClickSequence(
                  `copySequence${row}`,
                  `copiedSequence${row}`
                )
              }
              onMouseEnter={() => handleMouseEnter(`copySequence${row}`)}
              onMouseLeave={() =>
                handleMouseLeave(`copySequence${row}`, `copiedSequence${row}`)
              }
            >
              <FontAwesomeIcon icon={faCopy} color="rgb(0,0,0,0.8)" />
            </button>
            <div
              id={`copySequence${row}`}
              className="copySequence"
              style={{ right: 0, top: "-1.6rem" }}
            >
              <nobr style={{ display: "inline-flex" }}>Copy sequence</nobr>
            </div>
            <div
              id={`copiedSequence${row}`}
              className="copiedSequence"
              style={{ right: 0, top: "-1.6rem" }}
            >
              <nobr style={{ display: "inline-flex" }}>
                <p style={{ margin: "0 auto" }}>
                  <FontAwesomeIcon icon={faCheck} color="white" /> Copied
                </p>
              </nobr>
            </div>
            <p id={`sequence${row}`} style={{ display: "none" }}>
              {table[row].aminoacid_sequence}
            </p>
          </li>
        </>
      ) : (
        <></>
      )}
    </ul>
  );
}

export default CopySequence;
