import CheckboxInput from "./chechboxInput.js";

function ClassFilters({ updateForm,searchParams }) {
  return (
    <>
      <CheckboxInput
        idLabel={"classi"}
        idInput={"ClassI"}
        inputText={"Class I"}
        name={"one"}
        value={1}
        updateForm={updateForm}
        searchParams={searchParams}
      />

      <CheckboxInput
        idLabel={"classii"}
        idInput={"ClassII"}
        inputText={"Class II"}
        name={"two"}
        value={2}
        updateForm={updateForm}
        searchParams={searchParams}
      />
    </>
  );
}

export default ClassFilters;
