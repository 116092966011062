import React from "react";
import { Link } from "react-router-dom";
import "./HomeNavbar.css";
import useAuth from "./hooks/useAuth.js";
import useLogout from "./hooks/useLogout.js";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MARTSDB from "./MARTS-DB.svg";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useLocation } from "react-router-dom";

function HomeNavbar({
  suggestionCount,
  reloadAdvanced,
  reloadQuick
}) {
  const [mouseOverSuggestion, setMouseOverSuggestion] = useState(false);
  const [mouseOverEntry, setMouseOverEntry] = useState(false);
  const logout = useLogout();
  const { auth } = useAuth();
  const navigate = useNavigate()
  const location = useLocation()

  function dropdown(id) {
    const dropdown = document.getElementById(id);
    dropdown?.classList?.add("displayed");
    if (dropdown.style.display === "none") {
      dropdown.style.display = "block";
    } else {
      dropdown.style.display = "none";
    }
  }

  window.onclick = function (event) {
    if (
      !(
        event.target.matches(".dropbtn") ||
        event.target.matches("#SuggestionButton") ||
        event.target.matches("#CommentButton")
      )
    ) {
      const dropdown = document.getElementById("dropdownProfile");
      if (dropdown?.classList?.contains("displayed")) {
        dropdown?.classList?.remove("displayed");
        dropdown.style.display = "none";
      }
    }
    if (!event.target.matches(".dropbtnsrch")) {
      const search = document.getElementById("dropDownSearch");
      if (search?.classList?.contains("displayed")) {
        search?.classList?.remove("displayed");
        search.style.display = "none";
      }
    }
    if (!event.target.matches(".dropbtnsggst")) {
      const suggest = document.getElementById("dropDownSuggest");
      if (suggest?.classList?.contains("displayed")) {
        suggest?.classList?.remove("displayed");
        suggest.style.display = "none";
      }
    }
    if (!event.target.matches(".dropbtnntr")) {
      const entry = document.getElementById("dropDownEntry");
      if (entry?.classList?.contains("displayed")) {
        entry?.classList?.remove("displayed");
        entry.style.display = "none";
      }
    }
    if (
      !(
        event.target.matches(".suggestMechanism") ||
        event.target.matches("#faCommentMechanism") ||
        event.target.matches(".suggestDiv") ||
        event.target.matches(".suggestInput") ||
        event.target.matches(".confirmButton") ||
        event.target.matches(".innerTextSubmit")
      )
    ) {
      for (let i = 0; i < suggestionCount; i++) {
        if (document.getElementById(`suggestDiv${i}`)) {
          if (
            document.getElementById(`suggestDiv${i}`).style.display == "block"
          ) {
            document.getElementById(`suggestDiv${i}`).style.display = "none";
            document.getElementById(`suggestInput${i}`).value = "";
          }
        }
      }
    }
    if (
      !(
        event.target.matches(".suggestNote") ||
        event.target.matches("#faCommentNote") ||
        event.target.matches(".suggestDivNote") ||
        event.target.matches(".suggestInputNote") ||
        event.target.matches(".confirmButtonNote") ||
        event.target.matches(".innerTextSubmitNote")
      )
    ) {
      for (let i = 0; i < suggestionCount; i++) {
        if (document.getElementById(`suggestDivNote${i}`)) {
          if (
            document.getElementById(`suggestDivNote${i}`).style.display ==
            "block"
          ) {
            document.getElementById(`suggestDivNote${i}`).style.display =
              "none";
            document.getElementById(`suggestInputNote${i}`).value = "";
          }
        }
      }
    }
  };

  const user = [2001, 5150, 1984];
  const editor = [1984, 5150];
  const admin = [5150];

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css"
          rel="stylesheet"
        />
        <link
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
          rel="stylesheet"
        />
      </head>
      <body>
        <nav id="HomeNavbar">
          <ul id="UL">
            <li id="HomeLi">
              <button
                style={{
                  padding: 0,
                  margin: 0,
                  backgroundColor: "rgb(23,55,151)",
                  color: "white",
                  border: "none",
                }}
                id="homePage"
                onClick={()=>{navigate("/");
                  if (location.pathname === "/") {
                    reloadQuick()
                  }
                }}
              >
                <img
                  src={MARTSDB}
                  alt="Home"
                  style={{
                    width: "inherit",
                    height: "inherit",
                    marginTop: "-17.5px",
                  }}
                ></img>
              </button>
            </li>
            <li id="LoginLi">
              <nobr>
                <Link
                  id="login"
                  to="/login"
                  style={
                    !auth?.accessToken
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  Log in
                </Link>
              </nobr>
            </li>
          </ul>
          <ul id="ul">
            <li
              style={{
                position: "relative",
                paddingBottom: 11,
                paddingTop: 13,
                pointerEvents: "none",
              }}
              id="searchList"
            >
              <button
                onClick={()=>{navigate("/advancedsearch");
                  if (location.pathname === "/advancedsearch") {
                    reloadAdvanced()
                  }}
                  }
                className="dropbtnsrch"
                id="searchNavBar"
                style={{
                  position: "relative",
                  backgroundColor: "rgb(23, 55, 151,0.0)",
                  border: "none",
                  color: "white",
                  pointerEvents: "auto",
                }}
              >
                <nobr>Advanced Search</nobr>
              </button>
              <div id="tipsSearch" style={{ position: "absolute" }}>
                Select multiple filters at once and search for a reaction or its
                mechanism.
              </div>
            </li>
            <li
              style={{
                position: "relative",
                paddingBottom: 11,
                paddingTop: 13,
              }}
              id="suggestList"
              onMouseOver={() => setMouseOverSuggestion(true)}
              onMouseLeave={() => setMouseOverSuggestion(false)}
            >
              <button
                onClick={() => {
                  dropdown("dropDownSuggest");
                }}
                className="dropbtnsggst"
                id="suggestNavBar"
                style={{
                  position: "relative",
                  backgroundColor: "rgb(23, 55, 151,0.0)",
                  border: "none",
                  color: "white",
                  pointerEvents: "auto",
                }}
                disabled={user.includes(auth?.roles) ? false : true}
              >
                Suggest
              </button>

              {mouseOverSuggestion ? (
                <div id="tipsSuggest" style={{ position: "absolute" }}>
                  Log in to make new suggestions. Registration system is
                  currently in development.
                </div>
              ) : (
                <></>
              )}
              <div
                id="dropDownSuggest"
                style={{ position: "absolute", display: "none" }}
              >
                <ul style={{ listStyle: "none", padding: 0 }}>
                  <li>
                    <Link
                      to="/suggestreaction"
                      style={{
                        display: "block",
                        color: "white",
                        textDecoration: "none",
                        fontSize: "1rem",
                      }}
                    >
                      Reactions
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/suggestmechanism"
                      style={{
                        display: "block",
                        color: "white",
                        textDecoration: "none",
                        fontSize: "1rem",
                      }}
                    >
                      Mechanism
                    </Link>
                  </li>
                </ul>
              </div>
            </li>

            <li
              style={{
                position: "relative",
                paddingBottom: 11,
                paddingTop: 13,
              }}
              id="entryList"
            >
              <button
                onClick={() => dropdown("dropDownEntry")}
                className="dropbtnntr"
                id="entryNavBar"
                style={{
                  position: "relative",
                  backgroundColor: "rgb(23, 55, 151,0.0)",
                  border: "none",
                  color: "white",
                  pointerEvents: "auto",
                }}
                disabled={editor.includes(auth?.roles) ? false : true}
                onMouseOver={() => setMouseOverEntry(true)}
                onMouseLeave={() => setMouseOverEntry(false)}
              >
                Entry
              </button>
              {mouseOverEntry ? (
                <div id="tipsEntry" style={{ position: "absolute" }}>
                  Only editors can access this resource. Content on how to
                  become an editor is currently in development.
                </div>
              ) : (
                <></>
              )}

              <div
                id="dropDownEntry"
                style={{ position: "absolute", display: "none" }}
              >
                <ul style={{ listStyle: "none", padding: 0 }}>
                  <li>
                    <Link
                      to="/reactionentry"
                      style={{
                        display: "block",
                        color: "white",
                        textDecoration: "none",
                        fontSize: "1rem",
                      }}
                    >
                      Reactions
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/mechanismentry"
                      style={{
                        display: "block",
                        color: "white",
                        textDecoration: "none",
                        fontSize: "1rem",
                      }}
                    >
                      Mechanism
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li style={{ position: "relative", paddingTop: 13, paddingBottom: 11 }} id="profileList">
              <nobr>
                <button
                  id="profileButton"
                  className="dropbtn"
                  onClick={() => dropdown("dropdownProfile")}
                  style={
                    auth?.accessToken
                      ? { display: "block"}
                      : { display: "none"}
                  }
                >
                  <FontAwesomeIcon icon={faUser} /> {auth.username}
                </button>
              </nobr>
              <div
                id="dropdownProfile"
                className="dropdiv"
                style={{ display: "none", color: "white" }}
              >
                <ul id="dropdownUl" style={{ color: "white" }}>
                  {editor.includes(auth?.roles) ? (
                    <>
                      <li id="dropdownList">
                        <button
                          id="SuggestionButton"
                          className="dropdownButton"
                          onClick={() => {
                            if (
                              document.getElementById("submenuSuggestion").style
                                .display === "none"
                            ) {
                              document.getElementById(
                                "submenuSuggestion"
                              ).style.display = "block";
                            } else {
                              document.getElementById(
                                "submenuSuggestion"
                              ).style.display = "none";
                            }
                          }}
                        >
                          Suggestions
                        </button>
                      </li>
                      <div
                        id="submenuSuggestion"
                        style={{
                          display: "none",
                          borderTop: "1px lightgrey solid",
                        }}
                      >
                        <li style={{ marginLeft: 10 }}>
                          <Link id="MyEntries" to="/mysuggestions">
                            <FontAwesomeIcon
                              icon={faAngleRight}
                              color="white"
                              style={{ fontSize: 12 }}
                            />{" "}
                            My Suggestions
                          </Link>
                        </li>
                        <li style={{ marginLeft: 10 }}>
                          <Link id="MyEntries" to="/suggestions">
                            <FontAwesomeIcon
                              icon={faAngleRight}
                              color="white"
                              style={{ fontSize: 12 }}
                            />{" "}
                            All Suggestions
                          </Link>
                        </li>
                      </div>
                    </>
                  ) : (
                    <li id="dropdownList">
                      <button
                        id="suggestionButton"
                        className="dropdownButton"
                        onClick={() => navigate("/mysuggestions")}
                      >
                        My suggestions
                      </button>
                    </li>
                  )}
                  {editor.includes(auth?.roles) ? (
                    <>
                      <li id="dropdownList">
                        <button
                          id="CommentButton"
                          className="dropdownButton"
                          onClick={() => {
                            if (
                              document.getElementById("submenuComment").style
                                .display === "none"
                            ) {
                              document.getElementById(
                                "submenuComment"
                              ).style.display = "block";
                            } else {
                              document.getElementById(
                                "submenuComment"
                              ).style.display = "none";
                            }
                          }}
                        >
                          Comments
                        </button>
                      </li>
                      <div
                        id="submenuComment"
                        style={{
                          display: "none",
                          borderTop: "1px lightgrey solid",
                        }}
                      >
                        <li style={{ marginLeft: 10 }}>
                          <Link id="MyEntries" to="/mycomments">
                            <FontAwesomeIcon
                              icon={faAngleRight}
                              color="white"
                              style={{ fontSize: 12 }}
                            />{" "}
                            My Comments
                          </Link>
                        </li>
                        <li style={{ marginLeft: 10 }}>
                          <Link id="MyEntries" to="/comments">
                            <FontAwesomeIcon
                              icon={faAngleRight}
                              color="white"
                              style={{ fontSize: 12 }}
                            />{" "}
                            All Comments
                          </Link>
                        </li>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  <li id="dropdownList">
                    <button
                      id="Logout"
                      className="dropdownButton"
                      onClick={logout}
                    >
                      Log out
                    </button>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </nav>
      </body>
    </html>
  );
}

export default HomeNavbar;
