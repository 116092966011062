function SmilesChebiRow({children}) {
    return (
        <ul
            style={{
              display: "flex",
              listStyle: "none",
              padding: 0,
              justifyContent: "space-between",
              margin: "0 auto"
            }}
          >
        {children}
          </ul>
    )
}

export default SmilesChebiRow