import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons";

function SeeSuggestedMechanism({table,row}) {
  return (
    <div
      style={{
        position: "relative",
        maxWidth: "fit-content",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      Mechanism: <br />
      <a
        id="mechanismPublication"
        style={{ position: "relative" }}
        href={`${table[row].mechanism_link}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faBook} color="rgb(0,0,0,0.8)" />
        <div id="goToMechanismPublication">
          <nobr>See suggested publication</nobr>
        </div>
      </a>
    </div>
  );
}

export default SeeSuggestedMechanism;
