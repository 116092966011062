import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";

function TextInputMandatory({inputId,value,updateForm,name,label}) {
  return (
    <>
      <label for={inputId}>
        <FontAwesomeIcon icon={faAsterisk} color="red" fontSize="12" /> {label}
      </label>
      <input id={inputId} name={name} onChange={updateForm} value={value} />
    </>
  );
}

export default TextInputMandatory
