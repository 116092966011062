import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

function CopySmiles({copyId,copyClass,copiedId,copiedClass,paragraphId,children}) {
  return (
    <>
      <div id={copyId} className={copyClass}>
        <div style={{ display: "inline-flex" }}>
          Copy SMILES:{" "}
          <p
            id={paragraphId}
            style={{
              color: "black",
              backgroundColor: "white",
              wordWrap: "break-word",
              maxWidth: "30ch",
              margin: "0 auto",
              marginRight: 2.5,
              marginLeft: 5,
              paddingRight: 5,
              paddingLeft: 5,
            }}
          >
            {children}
          </p>
        </div>
      </div>
      <div
        id={copiedId}
        className={copiedClass}
        style={{ top: "1.75rem" }}
      >
        <nobr style={{ display: "inline-flex" }}>
          <p style={{ margin: "0 auto" }}>
            <FontAwesomeIcon icon={faCheck} color="white" /> Copied to clipboard
          </p>
        </nobr>
      </div>
    </>
  );
}

export default CopySmiles;
