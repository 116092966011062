function ClearAdvancedFilters({ handleClearFilters, children }) {
  return (
    <button
      style={{
        marginTop: "10px",
        width: 200,
        backgroundColor: "white",
        borderBottom: "2px solid lightgrey",
        borderTop: "none",
        borderLeft: "none",
        borderRight: "none",
      }}
      onClick={handleClearFilters}
    >
      {children}
    </button>
  );
}

export default ClearAdvancedFilters;
