function MechanismWindow({ children }) {
  return (
    <div
      id="overlap"
      style={{
        display: "none",
        position: "fixed",
        top: 0,
        left: 0,
        overflow: "scroll",
        zIndex:500
      }}
    >
      <div id="mechDiv" style={{ display: "none" }}>
        {children}
      </div>
    </div>
  );
}

export default MechanismWindow;
