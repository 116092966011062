import useAxiosPrivate from "./useAxiosPrivate.js";

function useSubmitSuggestedAdditional() {
  const axiosPrivate = useAxiosPrivate();

  async function submitSuggestAdditional(row,table,note,specification,isNote) {
    const inputValue = document.getElementById(`suggestInput${note}${specification}${row}`).value;
    document.getElementById(`innerTextSubmitted${note}${specification}${row}`).style.display =
      "block";
    document.getElementById(`innerTextSubmit${note}${specification}${row}`).style.display = "none";
    const suggestionId = table[row].suggestion_id;
    try {
      await axiosPrivate.post("/mechanismlink", {
        isNote,
        inputValue,
        suggestionId,
      });
    } catch (err) {
      throw err;
    } finally {
      setTimeout(() => {
        document.getElementById(`suggestDiv${note}${specification}${row}`).style.display = "none";
        document.getElementById(`innerTextSubmitted${note}${specification}${row}`).style.display =
          "none";
        document.getElementById(`innerTextSubmit${note}${specification}${row}`).style.display =
          "block";
      }, 1000);
    }
  }

  return submitSuggestAdditional;
}

export default useSubmitSuggestedAdditional;
